import { FC } from 'react';

import { ActionIcon } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';

import { BizPayRemoveButtonProps } from './BizPayRemoveButton.types';

import { USE_BIZPAY_UI_THEME_CONSTANTS } from '../../hooks';

const {
  colors: { redPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const BizPayRemoveButton: FC<BizPayRemoveButtonProps> = ({ onClick, ...otherActionIconProps }) => {
  return (
    <ActionIcon
      {...otherActionIconProps}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <IconTrash color={redPalette[5]} size={20} stroke={1} />
    </ActionIcon>
  );
};

export { BizPayRemoveButton };
