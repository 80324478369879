import { FC } from 'react';

import { IconChevronLeft } from '@tabler/icons-react';

import { BizPayCancelButton } from '../BizPayCancelButton';

import { BizPayBackButtonProps } from './BizPayBackButton.types';

const BizPayBackButton: FC<BizPayBackButtonProps> = (props) => {
  return (
    <BizPayCancelButton {...props}>
      <IconChevronLeft size={18} />
      Back
    </BizPayCancelButton>
  );
};

export { BizPayBackButton };
