import { compact, sentenceCase } from 'common';

const formatErrorMessage = (message: string): string => {
  const errorMessageParts = message.split(': ');
  const error = errorMessageParts.length === 1 ? ['', errorMessageParts[0]] : [errorMessageParts[0], errorMessageParts[1]];

  return compact(error)
    .map((item) => sentenceCase(item))
    .join(': ');
};

export { formatErrorMessage };
