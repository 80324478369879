import { FC } from 'react';

import { FullHeightContainer } from 'ui';

import { DocumentsTable } from '../DocumentsTable';

import { DocumentsTabProps } from './DocumentsTab.types';

const DocumentsTab: FC<DocumentsTabProps> = () => {
  return (
    <FullHeightContainer>
      <DocumentsTable
        paginationOptions={{
          isPaginationEnabled: true,
        }}
      />
    </FullHeightContainer>
  );
};

export { DocumentsTab };
