import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanApplicationForLoanApplicationConfirmationQuery: DocumentNode = gql`
  query retrieveLoanApplicationForLoanApplicationConfirmation($id: ID!) {
    retrieveLoanApplication(id: $id) {
      agreedToBizPayTermsAndConditionsAtUtc
      id
      invoiceDocument {
        id
        signedUrl
      }
      preliminaryPaymentAgreementDocument {
        id
        originalFileName
        signedUrl
      }
    }
  }
`;

const updateLoanApplicationAgreedToBizPayTermsAndConditionsMutation: DocumentNode = gql`
  mutation updateLoanApplicationAgreedToBizPayTermsAndConditions($id: ID!) {
    updateLoanApplicationAgreedToBizPayTermsAndConditions(id: $id) {
      agreedToBizPayTermsAndConditionsAtUtc
      id
    }
  }
`;

const updateLoanApplicationForSubmissionMutation: DocumentNode = gql`
  mutation updateLoanApplicationForSubmission($id: ID!) {
    updateLoanApplicationForSubmission(id: $id) {
      customerStatus
      id
    }
  }
`;

export {
  retrieveLoanApplicationForLoanApplicationConfirmationQuery,
  updateLoanApplicationAgreedToBizPayTermsAndConditionsMutation,
  updateLoanApplicationForSubmissionMutation,
};
