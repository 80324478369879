import { DocumentNode, gql } from 'graphql-library';

const createOrRetrieveLoanApplicationWithUploadInvoiceStatusMutation: DocumentNode = gql`
  mutation createOrRetrieveLoanApplicationWithUploadInvoiceStatus {
    createOrRetrieveLoanApplicationWithUploadInvoiceStatus {
      customerStatus
      id
      niceId
    }
  }
`;

const retrieveEntityStatusForLoggedInUserQuery: DocumentNode = gql`
  query retrieveEntityStatusForLoggedInUser {
    retrieveEntityForLoggedInUser {
      id
      status
    }
  }
`;

export { createOrRetrieveLoanApplicationWithUploadInvoiceStatusMutation, retrieveEntityStatusForLoggedInUserQuery };
