import { FC, useEffect } from 'react';

import { TextInput, Group, Loader } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';

import { createForgotPasswordFormZodResolver } from './ForgotPasswordForm.helpers';
import { ForgotPasswordFormData, ForgotPasswordFormProps } from './ForgotPasswordForm.types';

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ email, isLoading, onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setFocus,
  } = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email,
    },
    mode: 'onChange',
    resolver: createForgotPasswordFormZodResolver(),
  });

  useEffect(() => {
    setFocus('email');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { email: emailError } = errors;

  return (
    <form id="forgot-password-form-container" onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('email')}
        error={emailError ? <ErrorMessage message={emailError.message} /> : null}
        label="Email"
        placeholder="you@yourdomain.com"
        size="md"
        {...(email && {
          disabled: true,
          value: email,
        })}
      />

      <Group mt="3rem" noWrap>
        <BizPayPrimaryButton disabled={isLoading || !isValid} type="submit" fullWidth>
          {isLoading ? <Loader size="xs" /> : 'Get reset password link'}
        </BizPayPrimaryButton>
      </Group>
    </form>
  );
};

export { ForgotPasswordForm };
