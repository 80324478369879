import { FC } from 'react';

import { BizPayTooltip, Group, IconInfoCircle, Table, Text } from 'ui';

import { LoanFacilityDetailsTableProps } from './LoanFacilityDetailsTable.types';

const LoanFacilityDetailsTable: FC<LoanFacilityDetailsTableProps> = ({
  loanFacilityLimit,
  remainingLoanFacilityLimit,
  totalOutstandingLoanBalance,
  totalSubmittedLoanApplicationsAmounts,
}) => {
  return (
    <Table
      horizontalSpacing={8}
      sx={({ colors, white }) => {
        const borderColor = `0.1rem solid ${colors['bizpay-light-gray'][2]}`;

        return {
          borderBottom: borderColor,
          borderTop: borderColor,
          boxShadow: `0.05rem 0.05rem ${colors['bizpay-light-gray'][3]}`,

          '&[data-striped] tbody tr:nth-of-type(odd)': {
            backgroundColor: white,
            borderBottom: borderColor,
            borderTop: borderColor,
          },

          '&[data-striped] tbody tr:nth-of-type(even)': {
            backgroundColor: white,
            borderBottom: borderColor,
            borderTop: borderColor,
          },
        };
      }}
      verticalSpacing={8}
      striped
    >
      <tbody>
        <tr>
          <td>
            <Text fw="bold">Limit</Text>
          </td>
          <td>
            <Text align="right">{loanFacilityLimit}</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Group>
              <Text fw="bold" mr={3}>
                Loans
              </Text>

              <BizPayTooltip label="This is the total outstanding balance of all your active loans." position="top" w={250} multiline>
                <IconInfoCircle size={20} />
              </BizPayTooltip>
            </Group>
          </td>
          <td>
            <Text align="right">{totalOutstandingLoanBalance}</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Group>
              <Text fw="bold" mr={3}>
                Loan applications
              </Text>

              <BizPayTooltip
                label="This is the total loan amounts for your submitted loan applications. This amount may or may not count towards your loan facility limit depending on if your loan applications are approved or declined."
                position="top"
                w={250}
                multiline
              >
                <IconInfoCircle size={20} />
              </BizPayTooltip>
            </Group>
          </td>
          <td>
            <Text align="right">{totalSubmittedLoanApplicationsAmounts}</Text>
          </td>
        </tr>
        <tr>
          <td>
            <Text fw="bold">Remaining limit</Text>
          </td>
          <td>
            <Text align="right">{remainingLoanFacilityLimit}</Text>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export { LoanFacilityDetailsTable };
