import { FC, useEffect, useState } from 'react';

import { dayjs, isNil, useAuthentication, useNumberFormatter } from 'common';
import { useRetrieveLoanFacilityForLoggedInUsersEntityLazyQuery } from 'graphql-library';
import {
  BizPayLoader,
  Flex,
  Group,
  LoanFacilityUsagePieChart,
  PageHeading,
  RefreshDataControl,
  Stack,
  USE_BIZPAY_UI_THEME_CONSTANTS,
  useBizPayNotification,
} from 'ui';

import { LoanFacilityDetailsTable } from '../LoanFacilityDetailsTable';

import { LoanFacilityDetailsProps } from './LoanFacilityDetails.types';

import { useSignOut } from '../../hooks';

const {
  colors: { tealPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const LoanFacilityDetails: FC<LoanFacilityDetailsProps> = ({
  onLoadDataCompleted,
  refetchDataOptions: { shouldRefetchData, onRefetchDataCompleted },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { formatCurrency } = useNumberFormatter();
  const { signOut } = useSignOut();

  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [loanFacilityLimitInCents, setLoanFacilityLimitInCents] = useState<number>();
  const [remainingLoanFacilityLimitInCents, setRemainingLoanFacilityLimitInCents] = useState<number>();
  const [shouldRefetchDataInternal, setShouldRefetchDataInternal] = useState<boolean>(false);
  const [totalOutstandingLoanBalanceInCents, setTotalOutstandingLoanBalanceInCents] = useState<number>();
  const [totalSubmittedLoanApplicationsAmountsInCents, setTotalSubmittedLoanApplicationsAmountsInCents] = useState<number>();

  const [executeRetrieveLoanFacilityForLoggedInUsersEntityQuery, { loading: isLoading, refetch }] =
    useRetrieveLoanFacilityForLoggedInUsersEntityLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({
        retrieveLoanFacilityForLoggedInUsersEntity: {
          entity: { outstandingBalanceInCents },
          limitInCents,
          remainingLimitInCents,
          totalAmountInCentsOfNonDisbursedLoanApplications,
        },
      }) => {
        remainingLimitInCents = remainingLimitInCents < 0 ? 0 : remainingLimitInCents;
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setLoanFacilityLimitInCents(limitInCents);
        setRemainingLoanFacilityLimitInCents(remainingLimitInCents);
        setShouldRefetchDataInternal(false);
        setTotalOutstandingLoanBalanceInCents(outstandingBalanceInCents);
        setTotalSubmittedLoanApplicationsAmountsInCents(totalAmountInCentsOfNonDisbursedLoanApplications);

        onLoadDataCompleted(remainingLimitInCents);

        if (onRefetchDataCompleted) {
          onRefetchDataCompleted();
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve your loan facility',
        });
      },
    });

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchDataInternal(true);
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLoanFacilityForLoggedInUsersEntityQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!(shouldRefetchData || shouldRefetchDataInternal)) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData, shouldRefetchDataInternal]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <>
      <Group mb="xs" w="100%">
        <Flex w="60%">
          <PageHeading
            flexContainerProps={{
              ml: 8,
              mb: 0,
            }}
            heading="Loan facility"
            size="h4"
          />
        </Flex>

        <Flex justify="flex-end" w="40%">
          <RefreshDataControl
            dataType="loan-facility-limit"
            isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
            lastRetrievedDataAt={lastRetrievedDataAt}
            onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
          />
        </Flex>
      </Group>

      <Stack
        align="center"
        style={{
          position: 'relative',
        }}
      >
        {isLoading && <BizPayLoader message="Retrieving your loan facility..." />}

        {!isNil(loanFacilityLimitInCents) &&
          !isNil(remainingLoanFacilityLimitInCents) &&
          !isNil(totalOutstandingLoanBalanceInCents) &&
          !isNil(totalSubmittedLoanApplicationsAmountsInCents) && (
            <>
              <LoanFacilityDetailsTable
                loanFacilityLimit={formatCurrency(loanFacilityLimitInCents)}
                remainingLoanFacilityLimit={formatCurrency(remainingLoanFacilityLimitInCents)}
                totalOutstandingLoanBalance={formatCurrency(totalOutstandingLoanBalanceInCents)}
                totalSubmittedLoanApplicationsAmounts={formatCurrency(totalSubmittedLoanApplicationsAmountsInCents)}
              />

              <LoanFacilityUsagePieChart
                colorPalette={[tealPalette[3], tealPalette[4], tealPalette[5]]}
                data={[
                  {
                    groupName: 'Loan facility',
                    id: '1',
                    name: 'Remaining limit %',
                    value: (remainingLoanFacilityLimitInCents / loanFacilityLimitInCents) * 100,
                  },
                  {
                    groupName: 'Loan facility',
                    id: '2',
                    name: 'Loans %',
                    value: (totalOutstandingLoanBalanceInCents / loanFacilityLimitInCents) * 100,
                  },
                  {
                    groupName: 'Loan facility',
                    id: '3',
                    name: 'Loan applications %',
                    value: (totalSubmittedLoanApplicationsAmountsInCents / loanFacilityLimitInCents) * 100,
                  },
                ]}
                height={250}
                strokePalette={[tealPalette[4], tealPalette[5], tealPalette[6]]}
                width={200}
              />
            </>
          )}
      </Stack>
    </>
  );
};

export { LoanFacilityDetails };
