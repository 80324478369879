import { FC, useState } from 'react';

import { LoggedInUserData, useCookie, useDomain, useJwtDecoder } from 'common';
import {
  ApolloErrorGraphQLErrorExtensions,
  StatusCodes,
  useCustomerPortalSignInLazyQuery,
  useResetUserPasswordMutation,
} from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  BaseContainer,
  BizPayCustomerPortalDynamicNextJsTitle,
  DefaultPageLayout,
  Flex,
  formatErrorMessage,
  PageHeading,
  SignInForm,
  useBizPayNotification,
} from 'ui';

import { RESET_PASSWORD_COMPLETED_MESSAGE } from './SignInPage.constants';

import { ForgotPasswordLink, ResetPasswordModal, SignUpLink } from '../../components';
import { ProtectedRoute } from '../../hooks';

const SignInPage: FC = () => {
  const { displayNotification, displayErrorNotification } = useBizPayNotification();
  const { createAccessTokenCookie, createRefreshTokenCookie, isSecureCookie } = useCookie();
  const { getDomain } = useDomain();
  const { getDecodedJwt, getJwtExpiryDate } = useJwtDecoder<LoggedInUserData>();
  const { push } = useRouter();

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const [executeCustomerPortalSignInQuery, { loading: isLoading }] = useCustomerPortalSignInLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ customerPortalSignIn: { accessToken, refreshToken } }) => {
      const accessTokenExpiryDate = getJwtExpiryDate(getDecodedJwt(accessToken));

      const domain = getDomain();
      const secure = isSecureCookie(process.env.NEXT_PUBLIC_USE_SECURE_COOKIE);

      createAccessTokenCookie({
        optionalCookieAttributesInput: {
          domain,
          expires: accessTokenExpiryDate?.toUTCString(),
          secure,
        },
        value: accessToken,
      });

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure,
          },
          value: refreshToken,
        });
      }

      push(ProtectedRoute.Dashboard);
    },
    onError: ({ message }) => {
      displayErrorNotification({
        message: formatErrorMessage(message),
      });
    },
  });

  const [executeResetUserPasswordMutation, { loading: isResetUserPasswordLoading }] = useResetUserPasswordMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      displayNotification({
        message: RESET_PASSWORD_COMPLETED_MESSAGE,
      });

      setIsModalOpened(false);
    },
    onError: ({ graphQLErrors, message }) => {
      const [firstGraphQLError] = graphQLErrors;

      const isNotFoundException =
        (firstGraphQLError.extensions as unknown as ApolloErrorGraphQLErrorExtensions).status === StatusCodes.NOT_FOUND;

      const errorMessage = isNotFoundException ? RESET_PASSWORD_COMPLETED_MESSAGE : message;

      const displayNotificationHandler = isNotFoundException ? displayNotification : displayErrorNotification;

      displayNotificationHandler({
        message: errorMessage,
      });

      setIsModalOpened(false);
    },
  });

  const handleCloseModal = () => {
    setIsModalOpened(false);
  };

  const handleOpenModal = () => {
    setIsModalOpened(true);
  };

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle="Sign in" />

      <DefaultPageLayout
        mainComponent={
          <Flex align="center" h="100%" justify="center" w="100%">
            <Flex w={500}>
              <BaseContainer p="3rem">
                <PageHeading heading="Sign in to your account" mb="md" size="h4" />

                <SignInForm
                  forgotPasswordLinkComponent={
                    <ForgotPasswordLink label="Reset your password" textBefore="Did you forget your password?" onClick={handleOpenModal} />
                  }
                  isLoading={isLoading}
                  signUpLinkComponent={
                    <SignUpLink href={String(process.env.NEXT_PUBLIC_SIGN_UP_URL)} label="Sign up" textBefore="Don't have an account?" />
                  }
                  onSubmit={({ email, password }) => {
                    executeCustomerPortalSignInQuery({
                      variables: {
                        email,
                        password,
                      },
                    });
                  }}
                />

                <ResetPasswordModal
                  isLoading={isResetUserPasswordLoading}
                  isOpen={isModalOpened}
                  onClose={handleCloseModal}
                  onSubmit={(email) => {
                    executeResetUserPasswordMutation({
                      variables: {
                        email,
                      },
                    });
                  }}
                />
              </BaseContainer>
            </Flex>
          </Flex>
        }
      />
    </>
  );
};

export { SignInPage };
