import { FC } from 'react';

import { BizPayPieChart } from 'ui';

import { LoanRepaymentsUsagePieChartProps } from './LoanRepaymentsUsagePieChart.types';

const LoanRepaymentsUsagePieChart: FC<LoanRepaymentsUsagePieChartProps> = (props) => {
  return <BizPayPieChart {...props} />;
};

export { LoanRepaymentsUsagePieChart };
