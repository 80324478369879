import { FC } from 'react';

import { ActionIcon } from '@mantine/core';
import { IconZoomIn, IconZoomOut } from '@tabler/icons-react';

import { ZoomButtonProps } from './ZoomButton.types';

const ZoomButton: FC<ZoomButtonProps> = ({ onClick, zoomType, ...otherActionIconProps }) => {
  return (
    <ActionIcon
      size="lg"
      variant="transparent"
      onClick={onClick}
      {...otherActionIconProps}
      sx={({ colors }) => ({
        color: colors['bizpay-teal'][4],
      })}
    >
      {zoomType === 'in' && <IconZoomIn />}
      {zoomType === 'out' && <IconZoomOut />}
    </ActionIcon>
  );
};

export { ZoomButton };
