import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import {
  LoanApplicationCustomerStatus,
  useRetrieveLoanApplicationForLoanApplicationConfirmationLazyQuery,
  useUpdateLoanApplicationAgreedToBizPayTermsAndConditionsMutation,
  useUpdateLoanApplicationCustomerStatusMutation,
  useUpdateLoanApplicationForSubmissionMutation,
} from 'graphql-library';
import {
  BizPayBackButton,
  BizPayLoader,
  BizPayModal,
  BizPayPrimaryButton,
  Checkbox,
  Flex,
  Group,
  PageHeading,
  PdfViewer,
  Stack,
  Text,
  useBizPayNotification,
  useForm,
} from 'ui';

import { LoanApplicationDetails } from '../LoanApplicationDetails';

import { loanApplicationConfirmationFormZodResolver } from './LoanApplicationConfirmationModal.helpers';
import {
  LoanApplicationConfirmationModalProps,
  LoanApplicationConfirmationFormData,
  LoanApplicationForLoanApplicationConfirmation,
} from './LoanApplicationConfirmationModal.types';

import { useSignOut } from '../../hooks';

const LoanApplicationConfirmationModal: FC<LoanApplicationConfirmationModalProps> = ({
  isOpen,
  loanApplication: { id: loanApplicationId, niceId: loanApplicationNiceId },
  onClose,
  onContinue,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [agreedToBizPayTermsAndConditionsAtUtc, setAgreedToBizPayTermsAndConditionsAtUtc] = useState<string>();
  const [hasScrolledToTheEndOfThePaymentTermsAndConditions, setHasScrolledToTheEndOfThePaymentTermsAndConditions] =
    useState<boolean>(false);
  const [invoiceDocumentSignedUrl, setInvoiceDocumentSignedUrl] = useState<string>();
  const [isAgreedToBizPayTermsAndConditionsDisabled, setIsAgreedToBizPayTermsAndConditionsDisabled] = useState<boolean>(false);
  const [preliminaryPaymentAgreementDocument, setPreliminaryPaymentAgreementDocument] =
    useState<LoanApplicationForLoanApplicationConfirmation['preliminaryPaymentAgreementDocument']>();

  const [
    executeRetrieveLoanApplicationForLoanApplicationConfirmationQuery,
    { loading: isRetrieveLoanApplicationForLoanApplicationConfirmationLoading },
  ] = useRetrieveLoanApplicationForLoanApplicationConfirmationLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({
      retrieveLoanApplication: { agreedToBizPayTermsAndConditionsAtUtc, invoiceDocument, preliminaryPaymentAgreementDocument },
    }) => {
      setAgreedToBizPayTermsAndConditionsAtUtc(agreedToBizPayTermsAndConditionsAtUtc);
      setInvoiceDocumentSignedUrl(invoiceDocument?.signedUrl ?? undefined);
      setPreliminaryPaymentAgreementDocument(preliminaryPaymentAgreementDocument);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your loan application',
      });
    },
  });

  const [executeUpdateLoanApplicationAgreedToBizPayTermsAndConditionsMutation] =
    useUpdateLoanApplicationAgreedToBizPayTermsAndConditionsMutation({
      onCompleted: () => {
        setIsAgreedToBizPayTermsAndConditionsDisabled(true);
      },
    });

  const [executeUpdateLoanApplicationCustomerStatusMutation, { loading: isUpdateLoanApplicationCustomerStatusLoading }] =
    useUpdateLoanApplicationCustomerStatusMutation({
      onCompleted: ({ updateLoanApplicationCustomerStatus: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to update your loan application status',
        });
      },
    });

  const [executeUpdateLoanApplicationForSubmissionMutation, { loading: isUpdateLoanApplicationForSubmissionLoading }] =
    useUpdateLoanApplicationForSubmissionMutation({
      onCompleted: ({ updateLoanApplicationForSubmission: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to submit your loan application',
        });
      },
    });

  const handlePaymentTermsAndConditionsScrollPositionChange = ({ y }: { x: number; y: number }) => {
    if (hasScrolledToTheEndOfThePaymentTermsAndConditions) {
      return;
    }

    console.info('Payment Terms & Conditions', { y });
    setHasScrolledToTheEndOfThePaymentTermsAndConditions(y > 700); // TODO: Find a better way to detect the end of the scroll area
  };

  const handleClose = () => {
    setHasScrolledToTheEndOfThePaymentTermsAndConditions(false);
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrieveLoanApplicationForLoanApplicationConfirmationLoading || isUpdateLoanApplicationForSubmissionLoading;

  const {
    formState: { isValid },
    handleSubmit,
    register,
  } = useForm<LoanApplicationConfirmationFormData>({
    mode: 'onChange',
    resolver: loanApplicationConfirmationFormZodResolver(),
    values: {
      hasAgreedToPaymentTermsAndConditions: !!agreedToBizPayTermsAndConditionsAtUtc,
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!isOpen) {
      return;
    }

    executeRetrieveLoanApplicationForLoanApplicationConfirmationQuery({
      variables: {
        id: loanApplicationId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isOpen]);

  useEffect(() => {
    if (!isAgreedToBizPayTermsAndConditionsDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsAgreedToBizPayTermsAndConditionsDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_AGREE_TO_BIZPAY_PAYMENT_TERMS_AND_CONDITIONS_CHECKBOX_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isAgreedToBizPayTermsAndConditionsDisabled]);

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      closeOnEscape={!isLoading}
      opened={isOpen}
      size={875}
      subTitle={
        <LoanApplicationDetails invoiceDocumentSignedUrl={invoiceDocumentSignedUrl} loanApplicationNiceId={loanApplicationNiceId} />
      }
      title={
        <PageHeading
          flexContainerProps={{
            mb: 0,
          }}
          heading="Loan application confirmation"
          size="h4"
        />
      }
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      <Stack h={500} mt="md">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isRetrieveLoanApplicationForLoanApplicationConfirmationLoading && (
              <BizPayLoader message="Retrieving your loan application, please wait..." />
            )}

            {isUpdateLoanApplicationCustomerStatusLoading && (
              <BizPayLoader message="Returning to the select direct debit account step, please wait..." />
            )}

            {isUpdateLoanApplicationForSubmissionLoading && <BizPayLoader message="Submitting your loan application, please wait..." />}
          </Stack>
        ) : (
          <form
            id="loan-application-confirmation-form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
            onSubmit={handleSubmit(() => {
              if (!getIsAuthenticated()) {
                signOut();
                return;
              }

              executeUpdateLoanApplicationForSubmissionMutation({
                variables: {
                  id: loanApplicationId,
                },
              });
            })}
          >
            <Flex align="flex-start" direction="column" h="100%" justify="flex-start">
              <Text mb="md" weight="bold">
                Please scroll down to the end of the BizPay Payment Terms and Conditions to proceed.
              </Text>

              {preliminaryPaymentAgreementDocument && (
                <PdfViewer
                  canDownloadPdf={false}
                  fileName={preliminaryPaymentAgreementDocument.originalFileName}
                  initialPdfScale={1.2}
                  isZoomingEnabled={false}
                  loadingMessage="Loading preliminary payment agreement, please wait..."
                  url={preliminaryPaymentAgreementDocument.signedUrl}
                  onScrollPositionChange={handlePaymentTermsAndConditionsScrollPositionChange}
                />
              )}
              <Checkbox
                {...register('hasAgreedToPaymentTermsAndConditions')}
                data-testid="e2e-has-agreed-to-payment-terms-and-conditions-checkbox"
                disabled={!hasScrolledToTheEndOfThePaymentTermsAndConditions || isAgreedToBizPayTermsAndConditionsDisabled || isLoading}
                label={<Text size="sm">I hereby confirm that I have read and agreed to the BizPay Payment Terms and Conditions.</Text>}
                mt="md"
                onClick={() => {
                  if (!getIsAuthenticated()) {
                    signOut();
                    return;
                  }

                  executeUpdateLoanApplicationAgreedToBizPayTermsAndConditionsMutation({
                    variables: {
                      id: loanApplicationId,
                    },
                  });
                }}
              />
            </Flex>

            <Group mt="xl" w="100%">
              <Group position="left" w="50%">
                <BizPayBackButton
                  onClick={() => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeUpdateLoanApplicationCustomerStatusMutation({
                      variables: {
                        customerStatus: LoanApplicationCustomerStatus.SelectDirectDebitAccount,
                        id: loanApplicationId,
                      },
                    });
                  }}
                />
              </Group>

              <Group position="right" w="50%">
                <BizPayPrimaryButton disabled={!hasScrolledToTheEndOfThePaymentTermsAndConditions || !isValid} type="submit">
                  Submit loan application
                </BizPayPrimaryButton>
              </Group>
            </Group>
          </form>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { LoanApplicationConfirmationModal };
