import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication, useNumberFormatter } from 'common';
import { LoanStatus, useRetrievePaginatedLoansForCustomerPortalLoansPageLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  LoanStatusBadge,
  RefreshDataControl,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LoansTableProps, PaginatedLoan } from './LoansTable.types';

import { ProtectedRoute, useSignOut } from '../../hooks';

const LoansTable: FC<LoansTableProps> = ({ paginationOptions: { isPaginationEnabled = false, maxPageSize } }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { formatCurrency } = useNumberFormatter();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [loans, setLoans] = useState<PaginatedLoan[]>([]);
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedLoansForCustomerPortalLoansPageQuery, { refetch }] =
    useRetrievePaginatedLoansForCustomerPortalLoansPageLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrievePaginatedLoansForLoggedInUsersEntity: { count, data } }) => {
        setHasRetrievedData(true);
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setLoadingMessage(undefined);
        setLoans(data);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);

        if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
          push(`${route}?pageNumber=${currentPageNumber}`, undefined, {
            shallow: true,
          });
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve loans',
        });
      },
    });

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<PaginatedLoan>();

    return [
      columnHelper.accessor('niceId', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Id',
        size: 200,
      }),
      columnHelper.accessor('invoice.supplier.name', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Supplier',
        size: 200,
      }),
      columnHelper.accessor('status', {
        cell: ({ getValue }) => <LoanStatusBadge status={LoanStatus[getValue()]} />,
        enableSorting: false,
        header: 'Status',
        meta: {
          align: 'center',
        },
        size: 120,
      }),
      columnHelper.accessor('nextLoanRepayment.dateDueAtUtc', {
        cell: ({ getValue }) => {
          const dateDueAtUtc = getValue();
          return dateDueAtUtc ? dayjs(dateDueAtUtc).toDate().toLocaleDateString() : '-';
        },
        enableSorting: false,
        header: 'Next repayment date',
        size: 200,
      }),
      columnHelper.accessor('nextLoanRepayment.totalAmountInCents', {
        cell: ({ getValue }) => {
          const totalAmountInCents = getValue();
          return totalAmountInCents ? formatCurrency(totalAmountInCents) : '-';
        },
        enableSorting: false,
        header: 'Next repayment amount',
        meta: {
          align: 'right',
        },
        size: 150,
      }),
      columnHelper.accessor('balanceInCents', {
        cell: ({ getValue }) => formatCurrency(getValue()),
        enableSorting: false,
        header: 'Outstanding balance',
        id: 'outstandingBalance',
        meta: {
          align: 'right',
        },
      }),
      columnHelper.accessor('totalAmountInCents', {
        cell: ({ getValue }) => formatCurrency(getValue()),
        enableSorting: false,
        header: 'Total loan amount',
        id: 'totalLoanAmount',
        meta: {
          align: 'right',
        },
        size: 150,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="loan"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          minSize: 10,
          size: 10,
        },
      ),
    ];
  };

  const handleLoanClick = ({ id }: PaginatedLoan) => {
    const returnRoute = `${route}?pageNumber=${currentPageNumber}`;
    push(`${ProtectedRoute.Loans}/${id}?returnRoute=${encodeURIComponent(returnRoute)}`);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchData(true);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<PaginatedLoan>({
    columns: getTableColumns(),
    data: loans,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    setLoadingMessage('Retrieving your loans, please wait...');

    executeRetrievePaginatedLoansForCustomerPortalLoansPageQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <TablePageLayout
      loadingMessage={loadingMessage}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<PaginatedLoan>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No loans found"
          rowModel={getRowModel()}
          onRowClick={handleLoanClick}
        />
      }
    />
  );
};

export { LoansTable };
