import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import isLength from 'validator/lib/isLength';
import { z } from 'zod';

import { accountNumberZodEffect, bsbZodEffect } from '../../zod';

const createDirectDebitAccountFormZodResolver = () => {
  return zodResolver(
    z.object({
      alias: z.string().optional(),
      accountName: z.string().min(1, {
        message: 'Please enter your account name',
      }),
      accountNumber: accountNumberZodEffect,
      bsb: bsbZodEffect,
      financialInstitutionName: z
        .string()
        .min(1, {
          message: 'Please enter your financial institution name',
        })
        .refine(
          (value) =>
            isLength(value, {
              min: 3,
            }),
          {
            message: 'Your financial institution name must be at least 3 characters',
          },
        ),
      hasAuthorisedDirectDebitAccount: z.boolean().refine((value) => value),
    }),
  );
};

export { createDirectDebitAccountFormZodResolver };
