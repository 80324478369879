import { FC, useState } from 'react';

import { isNil } from 'common';
import { LoanRepaymentStatus, LoanStatus } from 'graphql-library';
import {
  FullHeightContainer,
  Group,
  LoanRepaymentsTable,
  LoanRepaymentsTableProps,
  LoanStatusBadge,
  LoanTransactionsTable,
  Stack,
  Text,
  USE_BIZPAY_UI_THEME_CONSTANTS,
} from 'ui';

import { LoanRepaymentsUsagePieChart } from '../LoanRepaymentsUsagePieChart';

import { LoanPageLoanInfoTabProps } from './LoanPageLoanInfoTab.types';

import { useSignOut } from '../../hooks';

const {
  colors: { tealPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const LoanPageLoanInfoTab: FC<LoanPageLoanInfoTabProps> = ({ loan: { id, status } }) => {
  const { signOut } = useSignOut();

  const [pendingLoanRepaymentsPercentage, setPendingLoanRepaymentsPercentage] = useState<number>();
  const [receivedLoanRepaymentsPercentage, setReceivedLoanRepaymentsPercentage] = useState<number>();

  const handleLoanRepaymentsTableDataCompleted: LoanRepaymentsTableProps['onLoadTableDataCompleted'] = (count, data) => {
    const totalPendingLoanRepayments = data.filter(({ status }) => status === LoanRepaymentStatus.Pending).length;
    const totalReceivedLoanRepayments = data.filter(({ status }) => status === LoanRepaymentStatus.Received).length;

    setPendingLoanRepaymentsPercentage((totalPendingLoanRepayments / count) * 100);
    setReceivedLoanRepaymentsPercentage((totalReceivedLoanRepayments / count) * 100);
  };

  return (
    <Group align="flex-start" w="100%" noWrap>
      <Stack w="100%">
        <Text ml="xs" weight="bold">
          Upcoming repayments
        </Text>

        <FullHeightContainer mt="md">
          <LoanRepaymentsTable loanId={id} onLoadTableDataCompleted={handleLoanRepaymentsTableDataCompleted} onSignOut={signOut} />
        </FullHeightContainer>

        <Text ml="xs" mt="md" weight="bold">
          Transactions
        </Text>

        <FullHeightContainer mt="md">
          <LoanTransactionsTable loanId={id} onSignOut={signOut} />
        </FullHeightContainer>
      </Stack>

      <Stack pl="xs">
        <LoanStatusBadge status={LoanStatus[status]} />

        {!isNil(pendingLoanRepaymentsPercentage) && !isNil(receivedLoanRepaymentsPercentage) && (
          <LoanRepaymentsUsagePieChart
            colorPalette={[tealPalette[3], tealPalette[4], tealPalette[5]]}
            data={[
              {
                groupName: 'Loan repayments',
                id: '1',
                name: 'Pending repayments %',
                value: pendingLoanRepaymentsPercentage,
              },
              {
                groupName: 'Loan repayments',
                id: '2',
                name: 'Received repayments %',
                value: receivedLoanRepaymentsPercentage,
              },
            ]}
            height={200}
            strokePalette={[tealPalette[4], tealPalette[5], tealPalette[6]]}
            width={200}
          />
        )}
      </Stack>
    </Group>
  );
};

export { LoanPageLoanInfoTab };
