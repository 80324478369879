import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import {
  EntityStatus,
  LoanApplication,
  LoanApplicationCustomerStatus,
  useCreateOrRetrieveLoanApplicationWithUploadInvoiceStatusMutation,
  useRetrieveEntityStatusForLoggedInUserLazyQuery,
} from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  BaseContainer,
  BizPayCustomerPortalDynamicNextJsTitle,
  BizPayLoadingOverlay,
  BizPayPrimaryButton,
  DashboardPageLayout,
  PageHeading,
  Space,
  Stack,
  Tooltip,
  useBizPayNotification,
} from 'ui';

import { DashboardLoansTable } from '../DashboardLoansTable';
import { FinanceInvoiceFlow } from '../FinanceInvoiceFlow';
import { LoanApplicationsTable } from '../LoanApplicationsTable';
import { LoanFacilityDetails } from '../LoanFacilityDetails';

import { useNavigationRoutes, useSignOut } from '../../hooks';

const DashboardPage: FC = () => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();
  const { signOut } = useSignOut();

  const [entityStatus, setEntityStatus] = useState<EntityStatus>();
  const [isFinanceInvoiceButtonDisabled, setIsFinanceInvoiceButtonDisabled] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loanApplicationCustomerStatus, setLoanApplicationCustomerStatus] = useState<LoanApplicationCustomerStatus>();
  const [loanApplicationId, setLoanApplicationId] = useState<string>();
  const [loanApplicationNiceId, setLoanApplicationNiceId] = useState<number>();
  const [shouldRefetchLoanApplicationData, setShouldRefetchLoanApplicationData] = useState<boolean>(false);
  const [shouldRefetchLoanFacilityDetails, setShouldRefetchLoanFacilityDetails] = useState<boolean>(false);

  const [
    executeCreateOrRetrieveLoanApplicationWithUploadInvoiceStatusMutation,
    { loading: isCreateOrRetrieveLoanApplicationWithUploadInvoiceStatusLoading },
  ] = useCreateOrRetrieveLoanApplicationWithUploadInvoiceStatusMutation({
    onCompleted: ({ createOrRetrieveLoanApplicationWithUploadInvoiceStatus: { customerStatus, id, niceId } }) => {
      setIsModalOpen(true);
      setLoanApplicationId(id);
      setLoanApplicationNiceId(niceId);
      setLoanApplicationCustomerStatus(customerStatus);
      setShouldRefetchLoanApplicationData(true);
    },
    onError: ({ message }) => {
      displayErrorNotification({
        message,
      });
    },
  });

  const [executeRetrieveEntityStatusForLoggedInUserQuery] = useRetrieveEntityStatusForLoggedInUserLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveEntityForLoggedInUser: { status } }) => {
      setEntityStatus(status);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve entity status',
      });
    },
  });

  const handleContinueLoanApplication = (
    customerStatus: LoanApplication['customerStatus'],
    id: LoanApplication['id'],
    niceId: LoanApplication['niceId'],
  ) => {
    setIsModalOpen(true);
    setLoanApplicationCustomerStatus(customerStatus);
    setLoanApplicationId(id);
    setLoanApplicationNiceId(niceId);
  };

  const handleDeleteLoanApplication = () => {
    setIsModalOpen(false);
    setLoanApplicationCustomerStatus(undefined);
    setLoanApplicationId(undefined);
    setLoanApplicationNiceId(undefined);
    setShouldRefetchLoanApplicationData(true);
  };

  const handleFinanceInvoice = () => {
    if (!getIsAuthenticated()) {
      signOut();
      return;
    }

    executeCreateOrRetrieveLoanApplicationWithUploadInvoiceStatusMutation();
  };

  const handleFinanceInvoiceFlowModalContinue = (customerStatus: LoanApplication['customerStatus']) => {
    setLoanApplicationCustomerStatus(customerStatus);
    setShouldRefetchLoanApplicationData(true);
    setShouldRefetchLoanFacilityDetails(customerStatus === LoanApplicationCustomerStatus.Submitted);
  };

  const handleLoanFacilityLoadDataCompleted = (isEntitySuspended: boolean, remainingLoanFacilityLimitInCents: number) => {
    setIsFinanceInvoiceButtonDisabled(isEntitySuspended || remainingLoanFacilityLimitInCents <= 0);
  };

  const handleModalClose = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const handleRefetchLoanApplicationDataCompleted = () => {
    setShouldRefetchLoanApplicationData(false);
  };

  const handleRefetchLoanFacilityDetailsCompleted = () => {
    setShouldRefetchLoanFacilityDetails(false);
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveEntityStatusForLoggedInUserQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      {isCreateOrRetrieveLoanApplicationWithUploadInvoiceStatusLoading && (
        <BizPayLoadingOverlay message="Preparing your loan application, please wait..." />
      )}

      {loanApplicationId && loanApplicationNiceId && loanApplicationCustomerStatus && (
        <FinanceInvoiceFlow
          isModalOpen={isModalOpen}
          loanApplication={{
            customerStatus: loanApplicationCustomerStatus,
            id: loanApplicationId,
            niceId: loanApplicationNiceId,
          }}
          onModalClose={handleModalClose}
          onModalContinue={handleFinanceInvoiceFlowModalContinue}
        />
      )}

      <DashboardPageLayout
        leftPanelComponent={
          <>
            <PageHeading heading="Loan applications" size="h4" />

            <BaseContainer>
              <LoanApplicationsTable
                paginationOptions={{
                  isPaginationEnabled: true,
                }}
                shouldRefetchData={shouldRefetchLoanApplicationData}
                onContinueLoanApplication={handleContinueLoanApplication}
                onRefetchDataComplete={handleRefetchLoanApplicationDataCompleted}
                onWithdrawLoanApplication={handleDeleteLoanApplication}
              />
            </BaseContainer>

            <Space mt="md" />

            <PageHeading heading="Loans" size="h4" />

            <BaseContainer>
              <DashboardLoansTable
                paginationOptions={{
                  isPaginationEnabled: true,
                }}
              />
            </BaseContainer>
          </>
        }
        rightPanelComponent={
          <Stack pr={0} w={275}>
            <Tooltip label="We are currently not originating new loans" position="left">
              <BizPayPrimaryButton mb="sm">Finance invoice</BizPayPrimaryButton>
            </Tooltip>

            {entityStatus && (
              <LoanFacilityDetails
                refetchDataOptions={{
                  onRefetchDataCompleted: handleRefetchLoanFacilityDetailsCompleted,
                  shouldRefetchData: shouldRefetchLoanFacilityDetails,
                }}
                onLoadDataCompleted={(remainingLoanFacilityLimitInCents) =>
                  handleLoanFacilityLoadDataCompleted(entityStatus === EntityStatus.Suspended, remainingLoanFacilityLimitInCents)
                }
              />
            )}
          </Stack>
        }
      />
    </>
  );
};

export { DashboardPage };
