import { FC, useEffect } from 'react';

import { Flex, Grid, Loader, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { BIZPAY_COMMON_CONSTANTS } from 'common';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';
import { TextInputRightSection } from '../TextInputRightSection';

import { businessInfoFormZodResolver } from './BusinessInfoForm.helpers';
import { BusinessInfoFormData, BusinessInfoFormProps } from './BusinessInfoForm.types';

const { dateFormat } = BIZPAY_COMMON_CONSTANTS;

const BusinessInfoForm: FC<BusinessInfoFormProps<BusinessInfoFormData>> = ({ initialDefaultValues, isLoading = false, onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setFocus,
    watch,
  } = useForm<BusinessInfoFormData>({
    defaultValues: initialDefaultValues,
    mode: 'onChange',
    resolver: businessInfoFormZodResolver(),
  });

  useEffect(() => {
    setFocus('legalName');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { websiteUrl: websiteUrlError } = errors;

  return (
    <>
      <form
        id="business-info-form"
        style={{
          width: '100%',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput {...register('legalName')} label="Legal name" size="md" readOnly />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput {...register('abn')} label="ABN" size="md" readOnly />
          </Grid.Col>
        </Grid>

        <Grid gutter="sm">
          <Grid.Col span="auto">
            <DatePickerInput
              {...(register('onboardedDate'),
              {
                value: watch('onboardedDate'),
              })}
              label="Onboarded date"
              placeholder="Onboarded date"
              size="md"
              valueFormat={dateFormat}
              readOnly
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput {...register('acn')} label="ACN" size="md" readOnly />
          </Grid.Col>
        </Grid>

        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput {...register('businessType')} label="Business type" size="md" readOnly />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('websiteUrl')}
              disabled={isLoading}
              error={websiteUrlError ? <ErrorMessage message={websiteUrlError.message} /> : null}
              label="Website URL"
              placeholder="https://www.yourbusiness.com.au"
              rightSection={<TextInputRightSection error={websiteUrlError} value={watch('websiteUrl')} />}
              size="md"
            />
          </Grid.Col>
        </Grid>

        <Flex justify="flex-end">
          <BizPayPrimaryButton disabled={isLoading || !isValid} mt={30} type="submit">
            {isLoading ? <Loader size="xs" /> : 'Save'}
          </BizPayPrimaryButton>
        </Flex>
      </form>
    </>
  );
};

export { BusinessInfoForm };
