import { FC } from 'react';

import { Flex } from '@mantine/core';

import { DashboardPageLayoutProps } from './DashboardPageLayout.types';

const DashboardPageLayout: FC<DashboardPageLayoutProps> = ({ leftPanelComponent, rightPanelComponent }) => {
  return (
    <Flex direction="row" h="100%" id="dashboard-page-layout-component-container">
      <Flex direction="column" h="100%" id="dashboard-page-layout-left-panel-component-container" w="100%">
        {leftPanelComponent}
      </Flex>
      <Flex direction="column" h="100%" id="dashboard-page-layout-right-panel-component-container" pl="md">
        {rightPanelComponent}
      </Flex>
    </Flex>
  );
};

export { DashboardPageLayout };
