import { FC } from 'react';

import { IconChevronRight } from '@tabler/icons-react';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';

import { BizPayContinueButtonProps } from './BizPayContinueButton.types';

const BizPayContinueButton: FC<BizPayContinueButtonProps> = (props) => {
  return (
    <BizPayPrimaryButton {...props}>
      Continue
      <IconChevronRight size={18} />
    </BizPayPrimaryButton>
  );
};

export { BizPayContinueButton };
