import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedLoansForCustomerPortalLoansPageQuery: DocumentNode = gql`
  query retrievePaginatedLoansForCustomerPortalLoansPage($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedLoansForLoggedInUsersEntity(pagination: $pagination, sort: $sort) {
      count
      data {
        balanceInCents
        createdAtUtc
        entity {
          id
          name
        }
        id
        invoice {
          id
          supplier {
            id
            name
          }
        }
        nextLoanRepayment {
          dateDueAtUtc
          id
          totalAmountInCents
        }
        niceId
        status
        totalAmountInCents
      }
    }
  }
`;

export { retrievePaginatedLoansForCustomerPortalLoansPageQuery };
