import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import {
  DirectDebitAccountStatus,
  useDeleteDirectDebitAccountForLoggedInUserMutation,
  useRetrievePaginatedDirectDebitAccountsForLoggedInUsersEntityLazyQuery,
  useTablePagination,
} from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayConfirmationModal,
  BizPayPagination,
  BizPayRemoveButton,
  BizPayTable,
  BizPayProtectedAccountIcon,
  createColumnHelper,
  ErrorAlert,
  FullHeightContainer,
  getCoreRowModel,
  Group,
  IconAlertCircle,
  IconPoint,
  IconRefresh,
  SortingState,
  Stack,
  TablePageLayout,
  Text,
  Updater,
  useBizPayNotification,
  useBizPayUITheme,
  useReactTable,
} from 'ui';

import { AddDirectDebitAccountButton } from '../AddDirectDebitAccountButton/AddDirectDebitAccountButton';
import { DirectDebitAccountModal } from '../DirectDebitAccountModal';

import { DirectDebitAccount, DirectDebitAccountsTableProps } from './DirectDebitAccountsTable.types';

import { useSignOut } from '../../hooks';

const DirectDebitAccountsTable: FC<DirectDebitAccountsTableProps> = ({
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const {
    theme: { colors },
  } = useBizPayUITheme();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination({
    pageSize: 5,
  });
  const { query } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [directDebitAccount, setDirectDebitAccount] = useState<DirectDebitAccount>();
  const [directDebitAccounts, setDirectDebitAccounts] = useState<DirectDebitAccount[]>([]);
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isCreateDirectDebitAccountModalVisible, setIsCreateDirectDebitAccountModalVisible] = useState<boolean>(false);
  const [isRemoveDirectDebitAccountModalVisible, setIsRemoveDirectDebitAccountModalVisible] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [
    executeRetrievePaginatedDirectDebitAccountsForLoggedInUsersEntityQuery,
    { loading: isLoading, refetch: refetchDirectDebitAccountsForLoggedInUsersEntity },
  ] = useRetrievePaginatedDirectDebitAccountsForLoggedInUsersEntityLazyQuery({
    onCompleted: ({ retrievePaginatedDirectDebitAccountsForLoggedInUsersEntity: { count, data: returnedDirectDebitAccounts } }) => {
      setDirectDebitAccounts(returnedDirectDebitAccounts);
      setHasRetrievedData(true);
      setTotalPages(calculateTotalPages(currentPageSize, count));
      setTotalRecords(count);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your direct debit accounts',
      });
    },
  });

  const [executeDeleteDirectDebitAccountForLoggedInUserMutation, { loading: isDeleteDirectDebitAccountForLoggedInUserLoading }] =
    useDeleteDirectDebitAccountForLoggedInUserMutation({
      onCompleted: () => {
        displaySuccessNotification({
          message: 'Successfully removed your direct debit account',
        });

        refetchDirectDebitAccountsForLoggedInUsersEntity();
        setIsRemoveDirectDebitAccountModalVisible(false);
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message,
          title: 'Unable to delete your direct debit account',
        });
      },
    });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<DirectDebitAccount>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('status', {
        cell: ({ getValue }) => getStatusIcon(getValue()),
        header: undefined,
        maxSize: 20,
        size: 20,
      }),
      columnHelper.accessor('alias', {
        cell: ({ getValue }) => getValue() ?? '-',
        enableSorting: isSortingEnabled,
        header: 'Alias',
        size: 120,
      }),
      columnHelper.accessor('financialInstitutionName', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Bank name',
        minSize: 120,
        size: 0,
      }),
      columnHelper.accessor('bsb', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'BSB',
        size: 90,
      }),
      columnHelper.accessor('accountNumber', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Account number',
        size: 160,
      }),
      columnHelper.accessor('accountName', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Account name',
        minSize: 120,
        size: 0,
      }),
      columnHelper.accessor('id', {
        cell: ({
          getValue,
          row: {
            original: { status },
          },
        }) => <BizPayRemoveButton disabled={!hasApprovedStatus(status)} onClick={handleRemoveDirectDebitAccountModalOpen(getValue())} />,
        enableSorting: false,
        header: undefined,
        maxSize: 10,
        meta: {
          align: 'center',
        },
        size: 10,
      }),
    ];
  };

  const getStatusIcon = (status: DirectDebitAccountStatus) => {
    switch (status) {
      case DirectDebitAccountStatus.Approved: {
        return <IconPoint color={colors?.['bizpay-light-gray']?.[5]} size={18} />;
      }

      case DirectDebitAccountStatus.Rejected: {
        return <IconAlertCircle color={colors?.['bizpay-red']?.[8]} size={18} />;
      }

      default:
        return <IconRefresh color={colors?.['bizpay-teal']?.[5]} size={18} />;
    }
  };

  const handleConfirmationModalClose = () => {
    setIsRemoveDirectDebitAccountModalVisible(false);
  };

  const handleCreateDirectDebitAccountModalClose = () => {
    setIsCreateDirectDebitAccountModalVisible(false);
    refetchDirectDebitAccountsForLoggedInUsersEntity();
  };

  const handleCreateDirectDebitAccountModalOpen = () => {
    setIsCreateDirectDebitAccountModalVisible(true);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRemoveDirectDebitAccountModalOpen = (directDebitAccountId: string) => () => {
    const foundDirectDebitAccount = directDebitAccounts.find(({ id }) => directDebitAccountId === id);

    setDirectDebitAccount(foundDirectDebitAccount);
    setIsRemoveDirectDebitAccountModalVisible(true);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const hasApprovedStatus = (status: DirectDebitAccountStatus) => {
    return status === DirectDebitAccountStatus.Approved;
  };

  const hasOneRejectedStatus = (directDebitAccounts: DirectDebitAccount[]) => {
    return directDebitAccounts.some(({ status }) => hasRejectedStatus(status));
  };

  const hasRejectedStatus = (status: DirectDebitAccountStatus) => {
    return status === DirectDebitAccountStatus.Rejected;
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<DirectDebitAccount>({
    columns: getTableColumns(totalRecords),
    data: directDebitAccounts,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedDirectDebitAccountsForLoggedInUsersEntityQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  return (
    <>
      <BizPayConfirmationModal
        h={300}
        loadingMessage={isDeleteDirectDebitAccountForLoggedInUserLoading ? 'Removing direct debit account, please wait...' : undefined}
        opened={isRemoveDirectDebitAccountModalVisible}
        size={500}
        title="Remove direct debit account"
        onClose={handleConfirmationModalClose}
        onConfirm={() => {
          if (!getIsAuthenticated()) {
            signOut();
            return;
          }

          if (!directDebitAccount) {
            return;
          }

          executeDeleteDirectDebitAccountForLoggedInUserMutation({
            variables: {
              id: directDebitAccount.id,
            },
          });
        }}
      >
        <Text ta="center">Are you sure you want to remove this direct debit account?</Text>

        {directDebitAccount && (
          <Stack align="center" justify="center" mt="xl">
            <Group>
              <Text weight="bold">BSB: </Text>
              <Text>{directDebitAccount.bsb}</Text>
            </Group>

            <Group>
              <Text weight="bold">Account number: </Text>
              <Text>{directDebitAccount.accountNumber}</Text>
            </Group>

            <Group>
              <Text weight="bold">Account name: </Text>
              <Text>{directDebitAccount.accountName}</Text>
            </Group>
          </Stack>
        )}
      </BizPayConfirmationModal>

      <DirectDebitAccountModal
        isOpen={isCreateDirectDebitAccountModalVisible}
        totalDirectDebitAccounts={directDebitAccounts.length}
        onClose={handleCreateDirectDebitAccountModalClose}
      />

      {!isLoading && !directDebitAccounts.length ? (
        <Stack align="center" h="100%" justify="center">
          <Text mb="5rem">To be able to apply for a loan, you need to add at least one direct debit account for your business</Text>

          <BizPayProtectedAccountIcon />

          <AddDirectDebitAccountButton mt="5rem" onClick={handleCreateDirectDebitAccountModalOpen} />
        </Stack>
      ) : (
        <>
          <Group mb="md" position="right" w="100%">
            <AddDirectDebitAccountButton onClick={handleCreateDirectDebitAccountModalOpen} />
          </Group>

          <FullHeightContainer>
            <TablePageLayout
              loadingMessage={isLoading ? 'Retrieving your direct debit accounts...' : undefined}
              paginationComponent={
                <BizPayPagination
                  description={generatePaginationResultsDescription(
                    isPaginationEnabled,
                    currentPageNumber,
                    currentPageSize,
                    calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
                    totalRecords,
                  )}
                  descriptionFontSize={10}
                  hasRetrievedData={hasRetrievedData}
                  isEnabled={isPaginationEnabled}
                  pageSize={currentPageSize}
                  totalPages={totalPages}
                  totalRecords={totalRecords}
                  value={currentPageNumber}
                  onChange={handlePageNumberChange}
                />
              }
              tableComponent={
                <BizPayTable<DirectDebitAccount>
                  hasRetrievedData={hasRetrievedData}
                  headerGroups={getHeaderGroups()}
                  rowIndexToRowColorMap={Object.fromEntries(
                    directDebitAccounts.reduce((accumulator, { status }, index) => {
                      accumulator.set(index.toString(), hasRejectedStatus(status) ? colors?.['bizpay-red']?.[2] : undefined);

                      return accumulator;
                    }, new Map<string, string | undefined>()),
                  )}
                  rowModel={getRowModel()}
                />
              }
            />

            {hasOneRejectedStatus(directDebitAccounts) ? (
              <ErrorAlert mt="xs" title="Notice">
                Repayment could not be debited from the provided direct debit account. We will retry in 3 days.
              </ErrorAlert>
            ) : null}

            {/* TODO: Display this only if direct debit accounts are associated with an active loan */}

            {/* <Stack h="100%">
            <Text
              mb="xs"
              sx={({ colors }) => ({
                color: colors['bizpay-light-gray'][5],
              })}
            >
              Contact BizPay to get your active loans assigned to a different direct debit account.
            </Text>

            <Text
              sx={({ colors }) => ({
                color: colors['bizpay-light-gray'][5],
              })}
            >
              Phone: 1300 4 BIZPAY
              <br />
              Email: info@bizpay.com
            </Text>
          </Stack> */}
          </FullHeightContainer>
        </>
      )}
    </>
  );
};

export { DirectDebitAccountsTable };
