import { FC, useEffect, useState } from 'react';

import { compact, dayjs, sentenceCase, useAuthentication, useNumberFormatter } from 'common';
import {
  LoanApplicationAdminStatus,
  LoanApplicationCustomerStatus,
  useRetrievePaginatedLoanApplicationsForLoggedInUsersEntityLazyQuery,
  useTablePagination,
  useWithdrawLoanApplicationMutation,
} from 'graphql-library';
import {
  BizPayConfirmationModal,
  BizPayPagination,
  BizPayRemoveButton,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  RefreshDataControl,
  SortingState,
  TablePageLayout,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LoanApplicationsTableProps, LoanApplication } from './LoanApplicationsTable.types';

import { useSignOut } from '../../hooks';

const LoanApplicationsTable: FC<LoanApplicationsTableProps> = ({
  onContinueLoanApplication,
  onRefetchDataComplete,
  onWithdrawLoanApplication,
  paginationOptions: { isPaginationEnabled = false, maxPageSize = 3 },
  shouldRefetchData,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { formatCurrency } = useNumberFormatter();
  const { displayErrorNotification } = useBizPayNotification();
  const { calculateRecordsToSkip, calculateTotalPages, generatePaginationResultsDescription, getInitialPageSize } = useTablePagination();
  const { signOut } = useSignOut();

  const [confirmationModalLoadingMessage, setConfirmationModalLoadingMessage] = useState<string>();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [loanApplications, setLoanApplications] = useState<LoanApplication[]>([]);
  const [loadingMessage, setLoadingMessage] = useState<string>();
  const [selectedLoanApplicationId, setSelectedLoanApplicationId] = useState<string>();
  const [selectedLoanApplicationNiceId, setSelectedLoanApplicationNiceId] = useState<number>();
  const [shouldRefetchDataInternal, setShouldRefetchDataInternal] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'updatedAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedLoanApplicationsForLoggedInUsersEntityQuery, { refetch }] =
    useRetrievePaginatedLoanApplicationsForLoggedInUsersEntityLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrievePaginatedLoanApplicationsForLoggedInUsersEntity: { count, data } }) => {
        setHasRetrievedData(true);
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setLoadingMessage(undefined);
        setLoanApplications(data);
        setShouldRefetchDataInternal(false);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);

        onRefetchDataComplete();
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve your loan applications',
        });
      },
    });

  const [executeWithdrawLoanApplicationMutation] = useWithdrawLoanApplicationMutation({
    onCompleted: ({ withdrawLoanApplication: { id } }) => {
      handleWithdrawLoanApplicationConfirmationModalClose();

      setConfirmationModalLoadingMessage(undefined);
      setSelectedLoanApplicationId(undefined);
      setSelectedLoanApplicationNiceId(undefined);

      onWithdrawLoanApplication?.(id);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to withdraw loan application',
      });
    },
  });

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<LoanApplication>();

    return [
      columnHelper.accessor('niceId', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Id',
        size: 150,
      }),
      columnHelper.accessor(
        ({ invoice }) => ({
          invoice,
        }),
        {
          cell: ({ getValue }) => {
            const { invoice } = getValue();

            if (!invoice) {
              return '-';
            }

            return invoice.supplier?.name ?? '-';
          },
          enableSorting: false,
          header: 'Supplier',
          minSize: 0,
          size: 0,
        },
      ),
      columnHelper.accessor('product', {
        cell: ({ getValue }) => {
          const { displayName, name } = getValue() ?? {};

          return displayName ?? name ?? '-';
        },
        enableSorting: false,
        header: 'Selected repayment plan',
        size: 200,
      }),
      columnHelper.accessor(
        ({ invoice }) => ({
          invoice,
        }),
        {
          cell: ({ getValue }) => {
            const { invoice } = getValue();

            if (!invoice) {
              return '-';
            }

            return invoice.totalAmountInCents ? formatCurrency(invoice.totalAmountInCents) : '-';
          },
          enableSorting: false,
          header: 'Invoice amount',
          meta: {
            align: 'right',
          },
          size: 150,
        },
      ),
      columnHelper.accessor('totalLoanAmountInCents', {
        cell: ({ getValue }) => {
          const totalLoanAmountInCents = getValue();
          return totalLoanAmountInCents === 0 ? '-' : formatCurrency(totalLoanAmountInCents);
        },
        enableSorting: false,
        header: 'Loan amount',
        meta: {
          align: 'right',
        },
        size: 150,
      }),
      columnHelper.accessor('customerStatus', {
        cell: ({ getValue }) => {
          const status = getValue();

          return [
            LoanApplicationCustomerStatus.Confirm,
            LoanApplicationCustomerStatus.SelectDirectDebitAccount,
            LoanApplicationCustomerStatus.SelectRepaymentPlan,
            LoanApplicationCustomerStatus.UploadInvoice,
          ].includes(status)
            ? 'Incomplete'
            : sentenceCase(getValue());
        },
        enableSorting: false,
        header: 'Status',
        meta: {
          align: 'center',
        },
        size: 200,
      }),
      columnHelper.accessor('updatedAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: false,
        header: 'Date last updated',
        size: 200,
      }),
      columnHelper.accessor(
        ({ adminStatus, customerStatus, id, niceId }) => ({
          adminStatus,
          customerStatus,
          id,
          niceId,
        }),
        {
          cell: ({ getValue }) => {
            const { adminStatus, customerStatus, id, niceId } = getValue();

            return adminStatus === null ||
              (adminStatus === LoanApplicationAdminStatus.ReadyForReview && customerStatus === LoanApplicationCustomerStatus.Submitted) ? (
              <BizPayRemoveButton onClick={() => handleWithdrawLoanApplicationClick(id, niceId)} />
            ) : null;
          },
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="loan-application"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          minSize: 10,
          size: 10,
        },
      ),
    ];
  };

  const handleContinueLoanApplicationClick = ({ customerStatus, id, niceId }: LoanApplication) => {
    onContinueLoanApplication(customerStatus, id, niceId);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchDataInternal(true);
  };

  const handleWithdrawLoanApplicationClick = (id: LoanApplication['id'], niceId: LoanApplication['niceId']) => {
    setIsConfirmationModalOpen(true);
    setSelectedLoanApplicationId(id);
    setSelectedLoanApplicationNiceId(niceId);
  };

  const handleWithdrawLoanApplicationConfirmationModalClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleWithdrawLoanApplicationConfirmationModalConfirm = (loanApplicationId: LoanApplication['id']) => {
    setConfirmationModalLoadingMessage('Withdrawing your loan application, please wait...');

    executeWithdrawLoanApplicationMutation({
      variables: {
        id: loanApplicationId,
      },
    });
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<LoanApplication>({
    columns: getTableColumns(),
    data: loanApplications,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    setLoadingMessage('Retrieving your loan applications, please wait...');

    executeRetrievePaginatedLoanApplicationsForLoggedInUsersEntityQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!(shouldRefetchData || shouldRefetchDataInternal)) {
      return;
    }

    setLoadingMessage('Refetching your loan applications, please wait...');

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData, shouldRefetchDataInternal]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <>
      {selectedLoanApplicationId && selectedLoanApplicationNiceId && (
        <BizPayConfirmationModal
          loadingMessage={confirmationModalLoadingMessage}
          opened={isConfirmationModalOpen}
          size={700}
          title="Withdraw loan application"
          onClose={handleWithdrawLoanApplicationConfirmationModalClose}
          onConfirm={() => handleWithdrawLoanApplicationConfirmationModalConfirm(selectedLoanApplicationId)}
        >
          Are you sure you want to withdraw this loan application (ID: {selectedLoanApplicationNiceId})?
        </BizPayConfirmationModal>
      )}

      <TablePageLayout
        loadingMessage={loadingMessage}
        paginationComponent={
          <BizPayPagination
            description={generatePaginationResultsDescription(
              isPaginationEnabled,
              currentPageNumber,
              currentPageSize,
              calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
              totalRecords,
            )}
            hasRetrievedData={hasRetrievedData}
            isEnabled={isPaginationEnabled}
            pageSize={currentPageSize}
            totalPages={totalPages}
            totalRecords={totalRecords}
            value={currentPageNumber}
            onChange={handlePageNumberChange}
          />
        }
        tableComponent={
          <BizPayTable<LoanApplication>
            disableOnClickForRowIndexes={compact(
              loanApplications.map(({ adminStatus }, index) => (!!adminStatus ? index.toString() : undefined)),
            )}
            hasRetrievedData={hasRetrievedData}
            headerGroups={getHeaderGroups()}
            noRecordsMessage="You have no loan applications."
            rowModel={getRowModel()}
            onRowClick={handleContinueLoanApplicationClick}
          />
        }
      />
    </>
  );
};

export { LoanApplicationsTable };
