import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanApplicationForLoanApplicationSelectRepaymentPlanQuery: DocumentNode = gql`
  query retrieveLoanApplicationForLoanApplicationSelectRepaymentPlan($id: ID!) {
    retrieveLoanApplication(id: $id) {
      id
      invoice {
        document {
          id
          signedUrl
        }
        id
        totalAmountInCents
      }
      product {
        id
      }
    }
  }
`;

const retrieveRepaymentPlansQuery: DocumentNode = gql`
  query retrieveRepaymentPlans {
    retrieveProducts {
      displayName
      feeType
      feeValue
      frequencyType
      frequencyValue
      id
      name
    }
  }
`;

const updateLoanApplicationProductMutation: DocumentNode = gql`
  mutation updateLoanApplicationProduct($id: ID!, $productId: String!) {
    updateLoanApplicationProduct(id: $id, productId: $productId) {
      customerStatus
      id
    }
  }
`;

export {
  retrieveLoanApplicationForLoanApplicationSelectRepaymentPlanQuery,
  retrieveRepaymentPlansQuery,
  updateLoanApplicationProductMutation,
};
