import { FC } from 'react';

import { Anchor, Group, Text } from 'ui';

import { ForgotPasswordLinkProps } from './ForgotPasswordLink.types';

const ForgotPasswordLink: FC<ForgotPasswordLinkProps> = ({ label, leftIcon, textBefore, onClick }) => {
  return (
    <Group align="center">
      {leftIcon}
      <Text ml={leftIcon ? 5 : 0}>
        {textBefore} <Anchor onClick={onClick}>{label}</Anchor>
      </Text>
    </Group>
  );
};

export { ForgotPasswordLink };
