import { FC } from 'react';

import { DirectDebitAccountsTable } from '../DirectDebitAccountsTable';

const DirectDebitAccountsTab: FC = () => {
  return (
    <DirectDebitAccountsTable
      paginationOptions={{
        isPaginationEnabled: true,
      }}
    />
  );
};

export { DirectDebitAccountsTab };
