import { FC, useEffect, useState } from 'react';

import { get, useAuthentication } from 'common';
import {
  ApolloError,
  useChangeLoggedInUserPasswordMutation,
  useRetrieveLoggedInUserForProfileLazyQuery,
  useUpdateLoggedInUserMutation,
} from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  BizPayCustomerPortalDynamicNextJsTitle,
  BizPayLoadingOverlay,
  DefaultPageLayout,
  formatErrorMessage,
  FullHeightContainer,
  Group,
  IconLockSquareRounded,
  PageHeading,
  useBizPayNotification,
  UserProfileForm,
} from 'ui';

import { ChangePasswordModal } from '../ChangePasswordModal';
import { ForgotPasswordLink } from '../ForgotPasswordLink';

import { User } from './ProfilePage.types';

import { useNavigationRoutes, useSignOut } from '../../hooks';

const CHANGE_YOUR_PASSWORD_TEXT = 'Change your password';

const ProfilePage: FC = () => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();
  const { signOut } = useSignOut();

  const [isChangePasswordModalOpened, setIsChangePasswordModalOpened] = useState<boolean>(false);
  const [user, setUser] = useState<User>();

  const [executeChangeLoggedInUserPasswordMutation, { loading: isChangePasswordModalLoading }] = useChangeLoggedInUserPasswordMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ changeLoggedInUserPassword: { message } }) => {
      setIsChangePasswordModalOpened(false);

      displaySuccessNotification({
        message,
      });
    },
    onError: (error) => {
      const [graphQlError] = (error as ApolloError).graphQLErrors;

      const {
        extensions: { exception },
      } = graphQlError;

      const errorMessage =
        get(exception, 'name') === 'invalid_grant'
          ? 'Invalid credentials. Please make sure you have entered in the correct current password.'
          : formatErrorMessage(error.message);

      displayErrorNotification({
        message: errorMessage,
      });
    },
  });

  const [executeRetrieveLoggedInUserForProfileQuery, { loading: isRetrieveLoggedInUserForProfileLoading }] =
    useRetrieveLoggedInUserForProfileLazyQuery({
      onCompleted: ({ retrieveLoggedInUser: returnedUser }) => {
        setUser(returnedUser);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve your profile',
        });
      },
    });

  const [executeUpdateLoggedInUserMutation, { loading: isUpdateLoggedInUserLoading }] = useUpdateLoggedInUserMutation({
    onCompleted: ({ updateLoggedInUser: returnedLoggedInUser }) => {
      setUser(returnedLoggedInUser);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update your profile',
      });
    },
  });

  const handleChangePasswordModalClose = () => {
    setIsChangePasswordModalOpened(false);
  };

  const handleChangePasswordModalOpen = () => {
    setIsChangePasswordModalOpened(true);
  };

  const { title } = findPageByRoute(route);

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrieveLoggedInUserForProfileLoading || isUpdateLoggedInUserLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLoggedInUserForProfileQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      {isRetrieveLoggedInUserForProfileLoading && <BizPayLoadingOverlay message="Retrieving your profile..." />}

      {isUpdateLoggedInUserLoading && <BizPayLoadingOverlay message="Updating your profile..." />}

      <DefaultPageLayout
        mainComponent={
          <>
            {user && (
              <FullHeightContainer>
                <Group mb="md" position="right">
                  <ForgotPasswordLink
                    label={CHANGE_YOUR_PASSWORD_TEXT}
                    leftIcon={<IconLockSquareRounded />}
                    onClick={handleChangePasswordModalOpen}
                  />
                </Group>

                <ChangePasswordModal
                  isLoading={isChangePasswordModalLoading}
                  isOpen={isChangePasswordModalOpened}
                  title={CHANGE_YOUR_PASSWORD_TEXT}
                  onCancel={handleChangePasswordModalClose}
                  onSubmit={(currentPassword, newPassword) => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeChangeLoggedInUserPasswordMutation({
                      variables: {
                        currentPassword,
                        newPassword,
                      },
                    });
                  }}
                />

                <UserProfileForm
                  contactUsEmail={String(process.env.NEXT_PUBLIC_CONTACT_US_EMAIL)}
                  initialDefaultValues={{
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    mobileNumber: user.mobileNumber ?? '',
                  }}
                  isLoading={isLoading}
                  isChangeEmailNoteVisible
                  onSubmit={({ firstName, lastName, mobileNumber }) => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeUpdateLoggedInUserMutation({
                      variables: {
                        input: {
                          firstName,
                          lastName,
                          mobileNumber,
                        },
                      },
                    });
                  }}
                />
              </FullHeightContainer>
            )}
          </>
        }
        pageHeadingComponent={<PageHeading heading={title} size="h4" />}
      />
    </>
  );
};

export { ProfilePage };
