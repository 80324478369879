import { FC, useEffect, useState } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayCustomerPortalDynamicNextJsTitle, DefaultPageLayout, FullHeightContainer, PageHeading, Tabs, TabsValue } from 'ui';

import { BusinessInfoTab } from '../BusinessInfoTab';
import { DirectDebitAccountsTab } from '../DirectDebitAccountsTab';
import { DocumentsTab } from '../DocumentsTab';

import { BUSINESS_DETAILS_PAGE_CONSTANTS } from './BusinessDetailsPage.constants';
import { BusinessDetailsPageProps } from './BusinessDetailsPage.types';

import { useNavigationRoutes } from '../../hooks';

const { tabs } = BUSINESS_DETAILS_PAGE_CONSTANTS;

const BusinessDetailsPage: FC<BusinessDetailsPageProps> = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const {
    push,
    query: { tab },
    route,
  } = useRouter();

  const [tabIdValue, setTabIdValue] = useState<TabsValue>(tabs.config[0].id);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = (value: TabsValue) => {
    setTabIdValue(value);
    setTabIndex(tabs.config.findIndex(({ id }) => id === value));

    push(`${route}?tab=${value}`, undefined, {
      shallow: true,
    });
  };

  const isBusinessInfoTab = tabs.config[tabIndex].id === tabs.ids.businessInfo;
  const isDirectDebitAccountsTab = tabs.config[tabIndex].id === tabs.ids.directDebitAccounts;
  const isDocumentsTab = tabs.config[tabIndex].id === tabs.ids.documents;

  const { title } = findPageByRoute(route);

  useEffect(() => {
    if (!tab) {
      handleTabChange(tabs.config[0].id);
      return;
    }

    handleTabChange(String(tab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <DefaultPageLayout
        mainComponent={
          <FullHeightContainer>
            <Tabs
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              value={tabIdValue}
              onTabChange={handleTabChange}
            >
              <Tabs.List>
                {tabs.config.map(({ id, title }) => {
                  return (
                    <Tabs.Tab key={`tab-${id}`} value={id}>
                      {title}
                    </Tabs.Tab>
                  );
                })}
              </Tabs.List>

              <Tabs.Panel
                pt="md"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                value={tabs.config[tabIndex].id}
              >
                {isBusinessInfoTab && <BusinessInfoTab />}

                {isDirectDebitAccountsTab && <DirectDebitAccountsTab />}

                {isDocumentsTab && <DocumentsTab />}
              </Tabs.Panel>
            </Tabs>
          </FullHeightContainer>
        }
        pageHeadingComponent={<PageHeading heading={title} size="h4" />}
      />
    </>
  );
};

export { BusinessDetailsPage };
