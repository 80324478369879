import { sentenceCase } from 'common';

import { LoanPageTabsConfig } from './LoanPage.types';

const LOAN_INFO_TAB_ID = 'loan-info';

const LOAN_PAGE_TABS_CONFIG: LoanPageTabsConfig[] = [
  {
    id: LOAN_INFO_TAB_ID,
    title: sentenceCase(LOAN_INFO_TAB_ID),
  },
];

const LOAN_PAGE_CONSTANTS = Object.freeze({
  tabs: {
    config: LOAN_PAGE_TABS_CONFIG,
    ids: {
      loanInfo: LOAN_INFO_TAB_ID,
    },
  },
});

export { LOAN_PAGE_CONSTANTS };
