import { FC } from 'react';

import { useCreateDirectDebitAccountForCustomerMutation } from 'graphql-library';
import { BizPayLoader, BizPayModal, DirectDebitAccountForm, PageHeading, Stack, useBizPayNotification } from 'ui';

import { DirectDebitAccountModalProps } from './DirectDebitAccountModal.types';

const DirectDebitAccountModal: FC<DirectDebitAccountModalProps> = ({ isOpen, onClose, totalDirectDebitAccounts }) => {
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();

  const [executeCreateDirectDebitAccountMutation, { loading: isCreateDirectDebitAccountLoading }] =
    useCreateDirectDebitAccountForCustomerMutation({
      onCompleted: () => {
        displaySuccessNotification({
          message: 'Successfully added your new direct debit account',
        });
        onClose();
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to add your direct debit account',
        });
      },
    });

  return (
    <BizPayModal
      closeOnClickOutside={!isCreateDirectDebitAccountLoading}
      closeOnEscape={!isCreateDirectDebitAccountLoading}
      opened={isOpen}
      size={840}
      title={
        <PageHeading
          description={
            !totalDirectDebitAccounts
              ? 'To be able to apply for a loan, you need to add at least one direct debit account for your business.'
              : ''
          }
          flexContainerProps={{
            mb: 0,
          }}
          heading="Add direct debit account"
          size="h4"
        />
      }
      withCloseButton={!isCreateDirectDebitAccountLoading}
      onClose={onClose}
    >
      <Stack h={400} mt="xs" w="100%">
        {isCreateDirectDebitAccountLoading ? (
          <Stack align="center" h="100%" justify="center">
            <BizPayLoader message="Adding direct debit account, please wait..." />
          </Stack>
        ) : (
          <DirectDebitAccountForm
            isLoading={isCreateDirectDebitAccountLoading}
            submitButtonText="Add direct debit account"
            onSubmit={({ accountName, accountNumber, alias, bsb, financialInstitutionName }) => {
              executeCreateDirectDebitAccountMutation({
                variables: {
                  input: {
                    accountName,
                    accountNumber,
                    alias,
                    bsb,
                    financialInstitutionName,
                  },
                },
              });
            }}
          />
        )}
      </Stack>
    </BizPayModal>
  );
};

export { DirectDebitAccountModal };
