import { FC, useEffect, useState } from 'react';

import { dayjs, saveAs, sentenceCase, useAuthentication } from 'common';
import { useRetrievePaginatedDocumentsForLoggedInUsersEntityLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  Anchor,
  BizPayPagination,
  BizPayPrimaryButton,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  Group,
  SortingState,
  TablePageLayout,
  Updater,
  UploadEntityDocumentModal,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { Document, DocumentsTableProps } from './DocumentsTable.types';

import { useSignOut } from '../../hooks';

const DocumentsTable: FC<DocumentsTableProps> = ({ paginationOptions: { isPaginationEnabled = false, maxPageSize } }) => {
  const { getIsAuthenticated, getLoggedInUserData } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination({
    pageSize: 5,
  });
  const { query } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [documents, setDocuments] = useState<Document[]>([]);
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedDocumentsForLoggedInUsersEntityQuery, { loading: isLoading, refetch: refetchDocuments }] =
    useRetrievePaginatedDocumentsForLoggedInUsersEntityLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrievePaginatedDocumentsForLoggedInUsersEntity: { count, data } }) => {
        setDocuments(data);
        setHasRetrievedData(true);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve your documents',
        });
      },
    });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<Document>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor(
        ({ originalFileName, signedUrl }) => ({
          originalFileName,
          signedUrl,
        }),
        {
          cell: ({ getValue }) => {
            const { originalFileName, signedUrl } = getValue();

            return signedUrl ? (
              <Anchor onClick={() => handleDownloadInvoice(originalFileName, signedUrl)}>{originalFileName}</Anchor>
            ) : (
              originalFileName
            );
          },
          enableSorting: isSortingEnabled,
          header: 'File name',
          minSize: 0,
          size: 0,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor('type', {
        cell: ({ getValue }) => sentenceCase(getValue()),
        enableSorting: isSortingEnabled,
        header: 'Type',
        size: 250,
        sortDescFirst: false,
      }),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date uploaded',
        size: 200,
        sortDescFirst: false,
      }),
    ];
  };

  const handleDownloadInvoice = (originalFileName: string, url: string) => {
    saveAs(url, originalFileName);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const handleUploadEntityDocumentModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUploadEntityDocumentModalCompleted = () => {
    refetchDocuments();
    setIsModalOpen(false);
  };

  const handleUploadEntityDocumentModalOpen = () => {
    setIsModalOpen(true);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<Document>({
    columns: getTableColumns(totalRecords),
    data: documents,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedDocumentsForLoggedInUsersEntityQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  const { entityId } = getLoggedInUserData() ?? {};

  return (
    <>
      {entityId && (
        <>
          <UploadEntityDocumentModal
            endpointPath="upload-document-by-logged-in-user"
            entityId={entityId}
            isOpen={isModalOpen}
            onClose={handleUploadEntityDocumentModalClose}
            onCompleted={handleUploadEntityDocumentModalCompleted}
          />

          <Group mb="md" position="right">
            <BizPayPrimaryButton data-testid="e2e-documents-table-upload-new-document" onClick={handleUploadEntityDocumentModalOpen}>
              Upload new document
            </BizPayPrimaryButton>
          </Group>
        </>
      )}

      <TablePageLayout
        loadingMessage={isLoading ? 'Retrieving your documents...' : undefined}
        paginationComponent={
          <BizPayPagination
            description={generatePaginationResultsDescription(
              isPaginationEnabled,
              currentPageNumber,
              currentPageSize,
              calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
              totalRecords,
            )}
            hasRetrievedData={hasRetrievedData}
            isEnabled={isPaginationEnabled}
            pageSize={currentPageSize}
            totalPages={totalPages}
            totalRecords={totalRecords}
            value={currentPageNumber}
            onChange={handlePageNumberChange}
          />
        }
        tableComponent={
          <BizPayTable<Document>
            hasRetrievedData={hasRetrievedData}
            headerGroups={getHeaderGroups()}
            noRecordsMessage="No documents found"
            rowModel={getRowModel()}
          />
        }
      />
    </>
  );
};

export { DocumentsTable };
