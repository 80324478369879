import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanFacilityForLoggedInUsersEntityQuery: DocumentNode = gql`
  query retrieveLoanFacilityForLoggedInUsersEntity {
    retrieveLoanFacilityForLoggedInUsersEntity {
      entity {
        id
        outstandingBalanceInCents
      }
      id
      limitInCents
      remainingLimitInCents
      totalAmountInCentsOfNonDisbursedLoanApplications
    }
  }
`;

export { retrieveLoanFacilityForLoggedInUsersEntityQuery };
