import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { BizPayModal, ChangePasswordForm } from 'ui';

import { ChangePasswordModalProps } from './ChangePasswordModal.types';

import { useSignOut } from '../../hooks';

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ isLoading, isOpen, onCancel, onSubmit, title }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <BizPayModal opened={isOpen} title={title} withCloseButton onClose={onCancel}>
      <ChangePasswordForm isLoading={isLoading} onSubmit={({ currentPassword, newPassword }) => onSubmit(currentPassword, newPassword)} />
    </BizPayModal>
  );
};

export { ChangePasswordModal };
