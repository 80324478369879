import { useCookie, useDomain } from 'common';
import { useRouter } from 'next/router';

import { PublicRoute } from '../useNavigationRoutes';

import { UseSignOut } from './useSignOut.types';

const useSignOut = (): UseSignOut => {
  const { deleteAccessTokenCookie, deleteRefreshTokenCookie } = useCookie();
  const { getDomain } = useDomain();
  const { replace, route } = useRouter();

  const signOut: UseSignOut['signOut'] = () => {
    const domain = getDomain();

    deleteAccessTokenCookie({
      domain,
    });

    deleteRefreshTokenCookie({
      domain,
    });

    if (route === PublicRoute.SignIn) {
      return;
    }

    replace(PublicRoute.SignIn);
  };

  return {
    signOut,
  };
};

export { useSignOut };
