import { DocumentNode, gql } from 'graphql-library';

const updateDirectDebitAccountForLoggedInUserMutation: DocumentNode = gql`
  mutation updateDirectDebitAccountForLoggedInUser($id: ID!, $input: UpdateDirectDebitAccountInput!) {
    updateDirectDebitAccountForLoggedInUser(id: $id, input: $input) {
      accountName
      accountNumber
      alias
      bsb
      financialInstitutionName
      id
      isDefault
      status
    }
  }
`;

export { updateDirectDebitAccountForLoggedInUserMutation };
