import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { BizPayModal, BizPayPrimaryButton, Group, Stack, Text } from 'ui';

import { LoanApplicationSubmittedModalProps } from './LoanApplicationSubmittedModal.types';

import { useSignOut } from '../../hooks';

const LoanApplicationSubmittedModal: FC<LoanApplicationSubmittedModalProps> = ({ isOpen, onClose }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <BizPayModal
      opened={isOpen}
      size={600}
      title="Your loan application has been submitted!"
      closeOnClickOutside
      withCloseButton
      onClose={handleClose}
    >
      <Stack h={200} justify="center" mt="md">
        <Stack align="center" h="100%" justify="center">
          <Text>Our team will review your loan application and will notify you of the outcome via email within 24 hours.</Text>
        </Stack>

        <Group align="center" mt="xl" position="center" w="100%">
          <BizPayPrimaryButton onClick={onClose}>Close</BizPayPrimaryButton>
        </Group>
      </Stack>
    </BizPayModal>
  );
};

export { LoanApplicationSubmittedModal };
