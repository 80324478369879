import { FC, useEffect } from 'react';

import { dayjs } from 'common';
import currency from 'currency.js';
import {
  ABN_LENGTH,
  BizPayBackButton,
  BizPayContinueButton,
  DatePickerInput,
  ErrorMessage,
  Grid,
  Group,
  IconCalendar,
  Stack,
  TextInput,
  TextInputRightSection,
  Title,
  useForm,
} from 'ui';

import { loanApplicationConfirmInvoiceFormZodResolver } from './LoanApplicationConfirmInvoiceForm.helpers';
import { LoanApplicationConfirmInvoiceFormData, LoanApplicationConfirmInvoiceFormProps } from './LoanApplicationConfirmInvoiceForm.types';

const LoanApplicationConfirmInvoiceForm: FC<LoanApplicationConfirmInvoiceFormProps> = ({
  isLoading = false,
  onBackButtonClick,
  onSubmit,
  values,
}) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<LoanApplicationConfirmInvoiceFormData>({
    mode: 'onChange',
    resolver: loanApplicationConfirmInvoiceFormZodResolver(),
    values,
  });

  useEffect(() => {
    trigger();

    const { supplierAbn, supplierEmail } = values;

    if (!supplierAbn) {
      setFocus('supplierAbn');
      return;
    }

    if (!supplierEmail) {
      setFocus('supplierEmail');
      return;
    }

    setFocus('supplierPhone');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    invoiceAmountInDollars: invoiceAmountInDollarsError,
    invoiceDateDueAtUtc: invoiceDateDueAtUtcError,
    supplierAbn: supplierAbnError,
    supplierEmail: supplierEmailError,
    supplierPhone: supplierPhoneError,
  } = errors;

  const invoiceDateDueAtUtcValue = watch('invoiceDateDueAtUtc');

  return (
    <form
      id="loan-application-supplier-details-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      onSubmit={handleSubmit(({ invoiceAmountInDollars, supplierEmail, ...otherFormData }) => {
        onSubmit({
          ...otherFormData,
          invoiceAmountInCents: currency(invoiceAmountInDollars).intValue,
          supplierEmail: String(supplierEmail),
        });
      })}
    >
      <Stack h="100%">
        <Grid gutter="xs">
          <Grid.Col span="auto">
            <Title size="h6">Invoice details</Title>
          </Grid.Col>

          <Grid.Col span="auto">
            <Title size="h6">Supplier details</Title>
          </Grid.Col>
        </Grid>

        <Grid gutter="xs" mt="0.2rem">
          <Grid.Col span="auto">
            <TextInput
              {...register('invoiceAmountInDollars')}
              error={invoiceAmountInDollarsError ? <ErrorMessage message={invoiceAmountInDollarsError.message} /> : null}
              icon="$"
              label="Invoice amount"
              maxLength={11}
              rightSection={<TextInputRightSection error={invoiceAmountInDollarsError} value={watch('invoiceAmountInDollars')} />}
              size="md"
              withAsterisk
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('supplierAbn')}
              error={supplierAbnError ? <ErrorMessage message={supplierAbnError.message} /> : null}
              label="ABN"
              maxLength={ABN_LENGTH}
              rightSection={<TextInputRightSection error={supplierAbnError} value={watch('supplierAbn')} />}
              size="md"
              withAsterisk
            />
          </Grid.Col>
        </Grid>

        <Grid gutter="xs" mt="0.2rem">
          <Grid.Col span="auto">
            <DatePickerInput
              {...register('invoiceDateDueAtUtc')}
              data-testid="e2e-confirm-invoice-due-date"
              error={invoiceDateDueAtUtcError ? <ErrorMessage message={invoiceDateDueAtUtcError.message?.toString()} /> : null}
              icon={<IconCalendar size={20} stroke={1} />}
              label="Invoice due date"
              minDate={dayjs()
                .startOf('day')
                .add(Number(process.env.NEXT_PUBLIC_INVOICE_VALIDATION_MAX_DAYS_PRIOR_TO_INVOICE_DUE_DATE), 'day')
                .toDate()}
              placeholder="Please select a date"
              rightSection={<TextInputRightSection error={invoiceDateDueAtUtcError} value={watch('invoiceDateDueAtUtc')} />}
              size="md"
              value={invoiceDateDueAtUtcValue ? invoiceDateDueAtUtcValue : undefined}
              valueFormat="DD/MM/YYYY"
              withAsterisk
              onChange={(value) => {
                setValue('invoiceDateDueAtUtc', value ? dayjs(value).toDate() : undefined, {
                  shouldDirty: true,
                });
                trigger('invoiceDateDueAtUtc');
              }}
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('supplierEmail')}
              error={supplierEmailError ? <ErrorMessage message={supplierEmailError.message} /> : null}
              label="Email address"
              rightSection={<TextInputRightSection error={supplierEmailError} value={watch('supplierEmail')} />}
              size="md"
              withAsterisk
            />
          </Grid.Col>
        </Grid>

        <Grid gutter="xs" mt="0.2rem">
          <Grid.Col span="auto"></Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('supplierPhone')}
              error={supplierPhoneError ? <ErrorMessage message={supplierPhoneError.message} /> : null}
              label="Phone number"
              rightSection={<TextInputRightSection error={supplierPhoneError} value={watch('supplierPhone')} />}
              size="md"
            />
          </Grid.Col>
        </Grid>
      </Stack>

      <Group mt="md" w="100%">
        <Group position="left" w="50%">
          <BizPayBackButton disabled={isLoading} onClick={onBackButtonClick} />
        </Group>

        <Group position="right" w="50%">
          <BizPayContinueButton disabled={isLoading || !isValid} type="submit" />
        </Group>
      </Group>
    </form>
  );
};

export { LoanApplicationConfirmInvoiceForm };
