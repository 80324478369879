import { DocumentNode, gql } from 'graphql-library';

const updateLoanApplicationCustomerStatusMutation: DocumentNode = gql`
  mutation updateLoanApplicationCustomerStatus($customerStatus: LoanApplicationCustomerStatus!, $id: ID!) {
    updateLoanApplicationCustomerStatus(customerStatus: $customerStatus, id: $id) {
      customerStatus
      id
    }
  }
`;

const withdrawLoanApplicationMutation: DocumentNode = gql`
  mutation withdrawLoanApplication($id: ID!) {
    withdrawLoanApplication(id: $id) {
      customerStatus
      id
    }
  }
`;

export { updateLoanApplicationCustomerStatusMutation, withdrawLoanApplicationMutation };
