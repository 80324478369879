import { FC, useState } from 'react';

import { Group, ScrollArea, Stack } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { saveAs } from 'common';
import { Document, Page, pdfjs } from 'react-pdf';

import { BizPayLoader } from '../BizPayLoader';
import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorAlert } from '../ErrorAlert';
import { ZoomButton } from '../ZoomButton';

import { PDF_VIEWER_CONSTANTS } from './PdfViewer.constants';
import { PdfViewerProps } from './PdfViewer.types';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const { SCALE: PDF_SCALE } = PDF_VIEWER_CONSTANTS;

const PdfViewer: FC<PdfViewerProps> = ({
  canDownloadPdf = true,
  downloadButtonText = 'Download',
  fileName,
  initialPdfScale = PDF_SCALE.MIN,
  isZoomingEnabled = true,
  loadingMessage,
  onLoadCompleted,
  onScrollPositionChange,
  pageProps,
  scrollAreaHeight = 300,
  url,
}) => {
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [pdfScale, setPdfScale] = useState<number>(initialPdfScale);

  const handleDocumentLoadSuccess = (numPages: number) => {
    setNumberOfPages(numPages);
    onLoadCompleted?.();
  };

  const handleDownloadInvoice = (originalFileName: string, url: string) => {
    saveAs(url, originalFileName);
  };

  const handlePdfZoomIn = () => {
    setPdfScale((previousPdfScale) => {
      const newPdfScale = previousPdfScale + PDF_SCALE.STEP_SIZE;

      if (newPdfScale >= PDF_SCALE.MAX) {
        return PDF_SCALE.MAX;
      }

      return newPdfScale;
    });
  };

  const handlePdfZoomOut = () => {
    setPdfScale((previousPdfScale) => {
      const newPdfScale = previousPdfScale - PDF_SCALE.STEP_SIZE;

      if (newPdfScale <= PDF_SCALE.MIN) {
        return PDF_SCALE.MIN;
      }

      return newPdfScale;
    });
  };

  return (
    <>
      {(canDownloadPdf || isZoomingEnabled) && (
        <Group align="center" mb="xs" w="100%" noWrap>
          {canDownloadPdf && (
            <Group align="center" position="left" w="100%">
              <BizPayPrimaryButton leftIcon={<IconDownload size="1rem" />} onClick={() => handleDownloadInvoice(fileName, url)}>
                {downloadButtonText}
              </BizPayPrimaryButton>
            </Group>
          )}

          {isZoomingEnabled && (
            <Group align="center" position="right" w="100%" noWrap>
              <ZoomButton disabled={pdfScale === PDF_SCALE.MAX} zoomType="in" onClick={handlePdfZoomIn} />
              <ZoomButton disabled={pdfScale === PDF_SCALE.MIN} zoomType="out" onClick={handlePdfZoomOut} />
            </Group>
          )}
        </Group>
      )}

      <ScrollArea
        h={scrollAreaHeight}
        id="pdf-viewer-scroll-area"
        sx={({ colors }) => ({
          border: `0.075rem solid ${colors['bizpay-light-gray'][2]}`,
          borderRadius: '0.5rem',
        })}
        w="100%"
        onScrollPositionChange={onScrollPositionChange}
      >
        <Document
          error={
            <Stack align="center" h={scrollAreaHeight} justify="center">
              <ErrorAlert title="Error">Unable to load PDF</ErrorAlert>
            </Stack>
          }
          file={url}
          loading={
            <Stack align="center" h={scrollAreaHeight} justify="center">
              <BizPayLoader message={loadingMessage} />
            </Stack>
          }
          onLoadSuccess={({ numPages }) => handleDocumentLoadSuccess(numPages)}
        >
          {Array.from({
            length: numberOfPages,
          })
            .map((_, index) => index + 1)
            .map((pageNumber) => (
              <Page key={`page-number-${pageNumber}`} pageNumber={pageNumber} scale={pdfScale} {...pageProps} />
            ))}
        </Document>
      </ScrollArea>
    </>
  );
};

export { PdfViewer };
