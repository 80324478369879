import { FC } from 'react';

import { BIZPAY_COMMON_CONSTANTS } from 'common';

import { DynamicNextJsTitle } from '../DynamicNextJsTitle';

import { BizPayCustomerPortalDynamicNextJsTitleProps } from './BizPayCustomerPortalDynamicNextJsTitle.types';

const {
  appName: { customerPortal },
} = BIZPAY_COMMON_CONSTANTS;

const BizPayCustomerPortalDynamicNextJsTitle: FC<BizPayCustomerPortalDynamicNextJsTitleProps> = ({ headComponent, pageTitle }) => {
  return <DynamicNextJsTitle appName={customerPortal} headComponent={headComponent} pageTitle={pageTitle} />;
};

export { BizPayCustomerPortalDynamicNextJsTitle };
