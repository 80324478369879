import { FC, ReactElement, useEffect, useState } from 'react';

import { useAuthentication, useCookie } from 'common';
import { useRetrieveLoggedInUserDetailsForUserDropdownMenuLazyQuery } from 'graphql-library';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
  BizPayAppLayout,
  BizPayNavigationBar,
  BizPayNavigationLink,
  BizPayNavigationLinkProps,
  BizPayRefreshAppButton,
  IconMail,
  useBizPayNotification,
  UserDropdownMenu,
} from 'ui';

import { AppLayoutProps } from './AppLayout.types';

import { ProtectedRoute, PublicRoute, useNavigationRoutes, useSignOut } from '../../hooks';

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const {
    publicRuntimeConfig: { version: appVersion },
  } = getConfig();

  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { createAppVersionCookie, getAppVersionCookie } = useCookie();

  const { buildNavigationBarLinks, buildUserDropdownMenuNavigationLinks } = useNavigationRoutes();
  const { route } = useRouter();
  const { signOut } = useSignOut();

  const [canRefreshApp, setCanRefreshApp] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [headerRightSectionComponent, setHeaderRightSectionComponent] = useState<ReactElement>();
  const [navigationBarLinks, setNavigationBarLinks] = useState<BizPayNavigationLinkProps[]>();
  const [shouldSignOut, setShouldSignOut] = useState<boolean>(false);

  const [
    executeRetrieveLoggedInUserDetailsForUserDropdownMenuQuery,
    { called: hasRetrieveLoggedInUserDetailsForUserDropdownMenuQueryBeenCalled },
  ] = useRetrieveLoggedInUserDetailsForUserDropdownMenuLazyQuery({
    onCompleted: ({ retrieveLoggedInUser: { email, firstName, lastName } }) => {
      setHeaderRightSectionComponent(
        <UserDropdownMenu
          email={email}
          fullName={`${firstName} ${lastName}`}
          menuItems={buildUserDropdownMenuNavigationLinks()}
          onSignOut={handleSignOut}
        />,
      );
    },
    onError: () => {
      if (!getIsAuthenticated()) {
        return;
      }

      displayErrorNotification({
        message: 'Unable to retrieve user details',
      });
    },
  });

  const handleRefreshApp = () => {
    createAppVersionCookie({
      optionalCookieAttributesInput: {
        path: '/',
      },
      value: appVersion,
    });

    location.reload();
  };

  const handleSignOut = () => {
    setHeaderRightSectionComponent(undefined);
    setNavigationBarLinks(undefined);
    setShouldSignOut(true);
  };

  const isAuthenticated = getIsAuthenticated();
  const currentAppVersion = getAppVersionCookie();

  useEffect(() => {
    setHasMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentAppVersion) {
      createAppVersionCookie({
        optionalCookieAttributesInput: {
          path: '/',
        },
        value: appVersion,
      });

      return;
    }

    setCanRefreshApp(appVersion !== currentAppVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion, currentAppVersion]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleSignOut();
      return;
    }

    setNavigationBarLinks(buildNavigationBarLinks(route));

    if (hasRetrieveLoggedInUserDetailsForUserDropdownMenuQueryBeenCalled) {
      return;
    }

    // TODO: Work out why this query is being called during the logout process
    executeRetrieveLoggedInUserDetailsForUserDropdownMenuQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, route]);

  useEffect(() => {
    if (!shouldSignOut) {
      return;
    }

    signOut();
    setShouldSignOut(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSignOut]);

  if (!hasMounted) {
    return null;
  }

  return (
    <BizPayAppLayout
      appVersion={currentAppVersion}
      headerLogoUrl={isAuthenticated ? ProtectedRoute.Dashboard : undefined}
      headerRightSectionComponent={headerRightSectionComponent}
      navBarComponent={
        <>
          {navigationBarLinks && (
            <BizPayNavigationBar
              contactUsNavigationLinkComponent={
                <BizPayNavigationLink
                  icon={<IconMail size={20} />}
                  label="Contact us"
                  linkOptions={{
                    href: `mailto:${String(process.env.NEXT_PUBLIC_CONTACT_US_EMAIL)}`,
                    target: '_blank',
                  }}
                />
              }
              navigationLinks={navigationBarLinks}
            />
          )}
        </>
      }
      refreshAppButtonComponent={canRefreshApp && isAuthenticated ? <BizPayRefreshAppButton onClick={() => handleRefreshApp()} /> : <></>}
    >
      {(route === PublicRoute.SignIn || isAuthenticated) && children}
    </BizPayAppLayout>
  );
};

export { AppLayout };
