import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import { z } from 'zod';

const createSignInFormZodResolver = () => {
  return zodResolver(
    z.object({
      email: z
        .string()
        .min(1, {
          message: 'Please enter your email',
        })
        .refine((value) => isEmail(value), {
          message: 'Please enter a valid email',
        }),
      password: z
        .string()
        .min(1, {
          message: 'Please enter a password',
        })
        .refine(
          (value) =>
            isLength(value, {
              min: 8, // TODO: Get this value from the server
            }),
          {
            message: 'Password must be at least 8 characters long',
          },
        ),
    }),
  );
};

export { createSignInFormZodResolver };
