import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanForLoggedInUsersEntityQuery: DocumentNode = gql`
  query retrieveLoanForLoggedInUsersEntity($id: ID!) {
    retrieveLoanForLoggedInUsersEntity(id: $id) {
      id
      invoiceDocument {
        id
        originalFileName
        signedUrl
        type
      }
      niceId
      paymentAgreementDocument {
        id
        originalFileName
        signedUrl
        type
      }
      status
      totalAmountInCents
    }
  }
`;

export { retrieveLoanForLoggedInUsersEntityQuery };
