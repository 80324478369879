import { DocumentNode, gql } from 'graphql-library';

const createDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation: DocumentNode = gql`
  mutation createDirectDebitAccountForLoanApplicationSelectDirectDebitAccount($input: CreateDirectDebitAccountInput!) {
    createDirectDebitAccount(input: $input) {
      accountName
      accountNumber
      alias
      bsb
      id
    }
  }
`;

const retrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountQuery: DocumentNode = gql`
  query retrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccount {
    retrieveDirectDebitAccountsForLoggedInUsersEntity {
      accountName
      accountNumber
      alias
      bsb
      id
    }
  }
`;

const retrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountQuery: DocumentNode = gql`
  query retrieveLoanApplicationForLoanApplicationSelectDirectDebitAccount($id: ID!) {
    retrieveLoanApplication(id: $id) {
      directDebitAccount {
        id
      }
      id
      invoice {
        document {
          id
          signedUrl
        }
        id
        supplier {
          email
          id
        }
        totalAmountInCents
      }
      product {
        displayName
        feeType
        feeValue
        frequencyType
        frequencyValue
        id
        name
      }
    }
  }
`;

const updateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation: DocumentNode = gql`
  mutation updateDirectDebitAccountForLoanApplicationSelectDirectDebitAccount($id: ID!, $directDebitAccountId: String!) {
    updateLoanApplicationDirectDebitAccount(id: $id, directDebitAccountId: $directDebitAccountId) {
      customerStatus
      id
    }
  }
`;

export {
  createDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation,
  retrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountQuery,
  retrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountQuery,
  updateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation,
};
