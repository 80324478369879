import { FC } from 'react';

import { Anchor, Flex, Text } from 'ui';

import { SignUpLinkProps } from './SignUpLink.types';

const SignUpLink: FC<SignUpLinkProps> = ({ label, leftIcon, textBefore, href, onClick }) => {
  return (
    <Flex>
      {leftIcon}
      <Text ml={leftIcon ? 7 : 0}>
        {textBefore}{' '}
        <Anchor href={href} onClick={onClick}>
          {label}
        </Anchor>
      </Text>
    </Flex>
  );
};

export { SignUpLink };
