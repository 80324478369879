import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'ui/styles/globals.css';

import { FC, useMemo } from 'react';

import { Analytics } from '@vercel/analytics/react';
import { useAuthentication, useCookie } from 'common';
import { ApolloProvider, useApolloClient } from 'graphql-library';
import type { AppProps } from 'next/app';
import { MantineProvider, Notifications, useBizPayEmotionCache, useBizPayUITheme } from 'ui';

import { AppLayout } from '../components';

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { getApolloClient } = useApolloClient();
  const { getIsAuthenticated } = useAuthentication();
  const { emotionCache } = useBizPayEmotionCache();
  const { theme } = useBizPayUITheme();
  const { getAccessTokenCookie } = useCookie();

  const isAuthenticated = getIsAuthenticated();

  const apolloClient = useMemo(
    () => {
      return getApolloClient({
        accessToken: isAuthenticated ? getAccessTokenCookie() : undefined,
        apiBaseUrl: String(process.env.NEXT_PUBLIC_API_BASE_URL),
        gqlEndpointPath: String(process.env.NEXT_PUBLIC_API_ENDPOINT_PATH_GRAPHQL),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated],
  );

  return (
    <MantineProvider emotionCache={emotionCache} theme={theme} withGlobalStyles withNormalizeCSS>
      <Notifications autoClose={10000} position="top-center" />
      <ApolloProvider client={apolloClient}>
        <AppLayout>
          <Component {...pageProps} />
          <Analytics />
        </AppLayout>
      </ApolloProvider>
    </MantineProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;
