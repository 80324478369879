import { FC, useEffect, useState } from 'react';

import { AxiosError, ErrorCode, FileRejection, get, uniq, useAuthentication, useCookie, useFileUpload } from 'common';
import {
  Invoice,
  LoanApplicationCustomerStatus,
  useRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalLazyQuery,
  useUpdateLoanApplicationCustomerStatusMutation,
  useUpdateLoanApplicationInvoiceMutation,
} from 'graphql-library';
import {
  BizPayContinueButton,
  BizPayLoader,
  BizPayModal,
  ErrorAlert,
  FileUpload,
  FileUploadProps,
  Group,
  PageHeading,
  Stack,
  Text,
  generateFileRejectionErrorMessage,
  useBizPayNotification,
} from 'ui';

import { LoanApplicationDetails } from '../LoanApplicationDetails';

import { LoanApplicationUploadInvoiceModalProps } from './LoanApplicationUploadInvoiceModal.types';

import { useSignOut } from '../../hooks';

const LoanApplicationUploadInvoiceModal: FC<LoanApplicationUploadInvoiceModalProps> = ({
  isOpen,
  loanApplication: { id: loanApplicationId, niceId: loanApplicationNiceId },
  onClose,
  onContinue,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { getAccessTokenCookie } = useCookie();
  const { signOut } = useSignOut();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [invoiceDocumentSignedUrl, setInvoiceDocumentSignedUrl] = useState<string>();

  const [
    executeRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalQuery,
    { loading: isRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalLoading },
  ] = useRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanApplication: { invoiceDocument } }) => {
      setInvoiceDocumentSignedUrl(invoiceDocument?.signedUrl);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your loan application',
      });
    },
  });

  const [executeUpdateLoanApplicationCustomerStatusMutation] = useUpdateLoanApplicationCustomerStatusMutation({
    onCompleted: ({ updateLoanApplicationCustomerStatus: { customerStatus } }) => {
      onContinue(customerStatus);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to navigate to the supplier detail step',
      });
    },
  });

  const [executeUpdateLoanApplicationInvoiceMutation, { loading: isUpdateLoanApplicationInvoiceLoading }] =
    useUpdateLoanApplicationInvoiceMutation({
      onCompleted: ({ updateLoanApplicationInvoice: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to update your loan application',
        });
      },
    });

  const { getUploadFileFormData, uploadFile, uploadFileResponse } = useFileUpload<Invoice>({
    baseApiEndpointUrl: String(process.env.NEXT_PUBLIC_API_BASE_URL),
    onCompleted: ({ id }) => {
      executeUpdateLoanApplicationInvoiceMutation({
        variables: {
          id: loanApplicationId,
          invoiceId: id,
        },
      });
    },
    onError: (error: AxiosError) => {
      const { message } = get(error, 'response.data') as {
        message: string;
      };

      setErrorMessage(message ?? error.message);
    },
    path: '/invoice/upload',
    token: getAccessTokenCookie(),
  });

  const handleClose = () => {
    setErrorMessage(undefined);
    onClose();
  };

  const handleFileRejected = (fileRejections: FileRejection[]) => {
    setErrorMessage(
      uniq(
        fileRejections
          .flatMap(({ errors }) => errors)
          .map(({ code, message }) => {
            if (code === ErrorCode.FileInvalidType) {
              return generateFileRejectionErrorMessage();
            }

            return message;
          }),
      )
        .map((errorMessage) => `- ${errorMessage}`)
        .join('\n'),
    );
  };

  const handleFileUpload: FileUploadProps['onDrop'] = async (acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      return;
    }

    setErrorMessage(undefined);
    setInvoiceDocumentSignedUrl(undefined);

    const formData = getUploadFileFormData({
      file: acceptedFiles[0] as File,
    });
    formData.append('loanApplicationId', loanApplicationId);

    uploadFile({
      formData,
    });
  };

  const isAuthenticated = getIsAuthenticated();
  const isLoading =
    isRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalLoading ||
    isUpdateLoanApplicationInvoiceLoading ||
    uploadFileResponse.isLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!isOpen) {
      return;
    }

    executeRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalQuery({
      variables: {
        id: loanApplicationId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isOpen, loanApplicationId]);

  return (
    <BizPayModal
      closeOnClickOutside={!uploadFileResponse.isLoading}
      closeOnEscape={!uploadFileResponse.isLoading}
      keepMounted={false}
      opened={isOpen}
      size={875}
      subTitle={
        <LoanApplicationDetails invoiceDocumentSignedUrl={invoiceDocumentSignedUrl} loanApplicationNiceId={loanApplicationNiceId} />
      }
      title={
        <PageHeading
          flexContainerProps={{
            mb: 0,
          }}
          heading="Upload invoice"
          size="h4"
        />
      }
      withCloseButton={!uploadFileResponse.isLoading}
      onClose={handleClose}
    >
      <Stack h={500} justify="center" mt="md">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isRetrieveLoanApplicationForLoanApplicationUploadInvoiceModalLoading && (
              <BizPayLoader message="Retrieving your loan application, please wait..." />
            )}

            {isUpdateLoanApplicationInvoiceLoading && <BizPayLoader message="Updating your loan application, please wait..." />}

            {uploadFileResponse.isLoading && <BizPayLoader message="Uploading and analysing your invoice, please wait..." />}
          </Stack>
        ) : (
          <>
            <Stack align="center" h="100%" justify="center" w="100%">
              <FileUpload
                instructionText={`Drag and drop your invoice ${invoiceDocumentSignedUrl ? 'replacement' : ''} here`}
                selectFileButtonText={`Select an invoice ${invoiceDocumentSignedUrl ? 'replacement' : ''} from your files`}
                onDrop={handleFileUpload}
                onDropRejected={handleFileRejected}
              />

              {invoiceDocumentSignedUrl && (
                <>
                  <Text mt="md">or</Text>
                  <Text mt="md">Click on the &quot;Continue&quot; button to skip the invoice replacement.</Text>
                </>
              )}
            </Stack>

            {invoiceDocumentSignedUrl && (
              <Group align="center" mt="xl" position="right" w="100%">
                <BizPayContinueButton
                  onClick={() => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeUpdateLoanApplicationCustomerStatusMutation({
                      variables: {
                        customerStatus: LoanApplicationCustomerStatus.ConfirmInvoice,
                        id: loanApplicationId,
                      },
                    });
                  }}
                />
              </Group>
            )}

            {errorMessage && (
              <ErrorAlert mt="md" title="Upload invoice error" w="100%">
                {errorMessage}
              </ErrorAlert>
            )}
          </>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { LoanApplicationUploadInvoiceModal };
