import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import {
  LoanApplicationCustomerStatus,
  useProductHelpers,
  useRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountLazyQuery,
  useRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountLazyQuery,
  useUpdateLoanApplicationCustomerStatusMutation,
  useUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation,
  useCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation,
} from 'graphql-library';
import {
  BizPayBackButton,
  BizPayContinueButton,
  BizPayLoader,
  BizPayModal,
  DirectDebitAccountForm,
  Flex,
  Group,
  IconChevronRight,
  PageHeading,
  Select,
  Stack,
  Text,
  TextInput,
  useBizPayNotification,
  useForm,
} from 'ui';

import { LoanApplicationDetails } from '../LoanApplicationDetails';

import { LoanApplicationSelectDirectDebitAccountFormZodResolver } from './LoanApplicationSelectDirectDebitAccountModal.helpers';
import {
  LoanApplicationSelectDirectDebitAccountModalProps,
  DirectDebitAccountForLoanApplicationSelectDirectDebitAccount,
  LoanApplicationForLoanApplicationSelectDirectDebitAccount,
  LoanApplicationSelectDirectDebitAccountFormData,
  LoanApplicationForLoanApplicationSelectDirectDebitAccountInvoice,
  LoanApplicationForLoanApplicationSelectDirectDebitAccountProduct,
} from './LoanApplicationSelectDirectDebitAccountModal.types';

import { useSignOut } from '../../hooks';

const LoanApplicationSelectDirectDebitAccountModal: FC<LoanApplicationSelectDirectDebitAccountModalProps> = ({
  isOpen,
  loanApplication: { id: loanApplicationId, niceId: loanApplicationNiceId },
  onClose,
  onContinue,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { generateProductLoanRepaymentDescription } = useProductHelpers();
  const { signOut } = useSignOut();

  const [directDebitAccounts, setDirectDebitAccounts] = useState<DirectDebitAccountForLoanApplicationSelectDirectDebitAccount[]>([]);
  const [invoiceDocumentSignedUrl, setInvoiceDocumentSignedUrl] = useState<string>();
  const [selectedDirectDebitAccountId, setSelectedDirectDebitAccountId] =
    useState<NonNullable<LoanApplicationForLoanApplicationSelectDirectDebitAccount['directDebitAccount']>['id']>();
  const [selectedRepaymentPlanWithProductLoanRepaymentDescription, setSelectedRepaymentPlanWithProductLoanRepaymentDescription] =
    useState<string>();

  const [
    executeCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation,
    { loading: isCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading },
  ] = useCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation({
    onCompleted: ({ createDirectDebitAccount: returnedDirectDebitAccount }) => {
      setDirectDebitAccounts([returnedDirectDebitAccount]);
      setSelectedDirectDebitAccountId(returnedDirectDebitAccount.id);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to add your direct debit account',
      });
    },
  });

  const [
    executeRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountQuery,
    { loading: isRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountLoading },
  ] = useRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveDirectDebitAccountsForLoggedInUsersEntity: returnedDirectDebitAccounts }) => {
      setDirectDebitAccounts(returnedDirectDebitAccounts);
      setSelectedDirectDebitAccountId((previousSelectedDirectDebitAccountId) => {
        if (previousSelectedDirectDebitAccountId) {
          return previousSelectedDirectDebitAccountId;
        }

        return returnedDirectDebitAccounts.length === 1 ? returnedDirectDebitAccounts[0].id : undefined;
      });
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your direct debit accounts',
      });
    },
  });

  const [
    executeRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountQuery,
    { loading: isRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountLoading },
  ] = useRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanApplication: { directDebitAccount, invoice: returnedInvoice, product: returnedProduct } }) => {
      const invoice = returnedInvoice as LoanApplicationForLoanApplicationSelectDirectDebitAccountInvoice;
      const product = returnedProduct as LoanApplicationForLoanApplicationSelectDirectDebitAccountProduct;

      const { document, totalAmountInCents } = invoice;
      const { displayName, name } = product;

      setInvoiceDocumentSignedUrl(document.signedUrl ?? undefined);
      setSelectedDirectDebitAccountId(directDebitAccount?.id);
      setSelectedRepaymentPlanWithProductLoanRepaymentDescription(
        `${displayName ?? name} - ${generateProductLoanRepaymentDescription({
          product,
          totalInvoiceAmountInCents: totalAmountInCents,
        })}`,
      );

      executeRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountQuery();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your loan application',
      });
    },
  });

  const [executeUpdateLoanApplicationCustomerStatusMutation, { loading: isUpdateLoanApplicationCustomerStatusLoading }] =
    useUpdateLoanApplicationCustomerStatusMutation({
      onCompleted: ({ updateLoanApplicationCustomerStatus: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message: `Unable to update your loan application - ${message}`,
        });
      },
    });

  const [
    executeUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation,
    { loading: isUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading },
  ] = useUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation({
    onCompleted: ({ updateLoanApplicationDirectDebitAccount: { customerStatus } }) => {
      onContinue(customerStatus);
    },
    onError: ({ message }) => {
      displayErrorNotification({
        message: `Unable to update your loan application - ${message}`,
      });
    },
  });

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();
  const isLoading =
    isCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading ||
    isRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountLoading ||
    isRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountLoading ||
    isUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading;

  const {
    formState: { isValid },
    handleSubmit,
    register,
    resetField,
    setFocus,
    setValue,
    trigger,
  } = useForm<LoanApplicationSelectDirectDebitAccountFormData>({
    mode: 'onChange',
    resolver: LoanApplicationSelectDirectDebitAccountFormZodResolver(),
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!isOpen) {
      return;
    }

    resetField('directDebitAccountId');
    setFocus('directDebitAccountId');

    executeRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountQuery({
      variables: {
        id: loanApplicationId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isOpen]);

  useEffect(() => {
    // Note: This is needed since the Select component seems to
    // use the label as the value when setting its defaultValue
    if (!selectedDirectDebitAccountId) {
      return;
    }

    setValue('directDebitAccountId', selectedDirectDebitAccountId);
    trigger('directDebitAccountId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDirectDebitAccountId]);

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      closeOnEscape={!isLoading}
      opened={isOpen}
      size={875}
      subTitle={
        <LoanApplicationDetails invoiceDocumentSignedUrl={invoiceDocumentSignedUrl} loanApplicationNiceId={loanApplicationNiceId} />
      }
      title={
        <PageHeading
          flexContainerProps={{
            mb: 0,
          }}
          heading="Select/Confirm your direct debit account"
          size="h4"
        />
      }
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      <Stack h={500} mt="md">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading && (
              <BizPayLoader message="Adding your direct debit account, please wait..." />
            )}

            {isRetrieveDirectDebitAccountsForLoanApplicationSelectDirectDebitAccountLoading && (
              <BizPayLoader message="Retrieving your direct debit accounts, please wait..." />
            )}

            {isRetrieveLoanApplicationForLoanApplicationSelectDirectDebitAccountLoading && (
              <BizPayLoader message="Retrieving your loan application, please wait..." />
            )}

            {isUpdateLoanApplicationCustomerStatusLoading && (
              <BizPayLoader message="Returning to the select repayment plan step, please wait..." />
            )}

            {isUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading && (
              <BizPayLoader message="Updating your loan application, please wait..." />
            )}
          </Stack>
        ) : (
          <>
            {directDebitAccounts.length ? (
              <form
                id="loan-application-select-direct-debit-account-form"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  width: '100%',
                }}
                onSubmit={handleSubmit(({ directDebitAccountId }) => {
                  if (!getIsAuthenticated()) {
                    signOut();
                    return;
                  }

                  executeUpdateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation({
                    variables: {
                      directDebitAccountId: String(directDebitAccountId),
                      id: loanApplicationId,
                    },
                  });
                })}
              >
                <Flex align="flex-start" direction="column" h="100%" justify="flex-start">
                  {selectedRepaymentPlanWithProductLoanRepaymentDescription && (
                    <TextInput
                      label="Selected repayment plan"
                      size="md"
                      value={selectedRepaymentPlanWithProductLoanRepaymentDescription}
                      w="100%"
                      readOnly
                    />
                  )}

                  <Select
                    {...register('directDebitAccountId')}
                    data={directDebitAccounts.map(({ accountNumber, alias, bsb, id }) => {
                      const labelPrefix = alias ? `${alias} - ` : '';

                      return {
                        label: `${labelPrefix}BSB: ${bsb}, Account number: ${accountNumber}`,
                        selected: id === selectedDirectDebitAccountId,
                        value: id,
                      };
                    })}
                    defaultValue={selectedDirectDebitAccountId}
                    disabled={isLoading}
                    label="Direct debit accounts"
                    mt="md"
                    placeholder="Please select the bank account to direct debit from"
                    readOnly={directDebitAccounts.length === 1}
                    size="md"
                    sx={() => ({
                      input: {
                        ':hover': {
                          cursor: directDebitAccounts.length === 1 ? 'text' : 'pointer',
                        },
                      },
                    })}
                    w="100%"
                    onChange={(directDebitAccountId) => {
                      if (!directDebitAccountId) {
                        return;
                      }

                      setValue('directDebitAccountId', directDebitAccountId);
                      trigger('directDebitAccountId');
                    }}
                  />
                </Flex>

                <Group mt="xl" w="100%">
                  <Group position="left" w="50%">
                    <BizPayBackButton
                      onClick={() => {
                        if (!getIsAuthenticated()) {
                          signOut();
                          return;
                        }

                        executeUpdateLoanApplicationCustomerStatusMutation({
                          variables: {
                            customerStatus: LoanApplicationCustomerStatus.SelectRepaymentPlan,
                            id: loanApplicationId,
                          },
                        });
                      }}
                    />
                  </Group>

                  <Group position="right" w="50%">
                    <BizPayContinueButton disabled={!isValid} type="submit" />
                  </Group>
                </Group>
              </form>
            ) : (
              <>
                <Text weight="bold">No direct debit accounts found</Text>

                <Text mb="md">Please add a direct debit account to continue.</Text>

                <DirectDebitAccountForm
                  isLoading={isCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountLoading}
                  submitButtonText={
                    <>
                      Continue
                      <IconChevronRight />
                    </>
                  }
                  onSubmit={({ accountName, accountNumber, alias, bsb, financialInstitutionName }) => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeCreateDirectDebitAccountForLoanApplicationSelectDirectDebitAccountMutation({
                      variables: {
                        input: {
                          accountName,
                          accountNumber,
                          alias,
                          bsb,
                          financialInstitutionName,
                        },
                      },
                    });
                  }}
                />
              </>
            )}
          </>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { LoanApplicationSelectDirectDebitAccountModal };
