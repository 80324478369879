import { DocumentNode, gql } from 'graphql-library';

const deleteLoanApplicationMutation: DocumentNode = gql`
  mutation deleteLoanApplication($id: ID!) {
    deleteLoanApplication(id: $id) {
      id
    }
  }
`;

const retrievePaginatedLoanApplicationsForLoggedInUsersEntityQuery: DocumentNode = gql`
  query retrievePaginatedLoanApplicationsForLoggedInUsersEntity($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedLoanApplicationsForLoggedInUsersEntity(pagination: $pagination, sort: $sort) {
      count
      data {
        adminStatus
        customerStatus
        id
        invoice {
          id
          supplier {
            id
            name
          }
          totalAmountInCents
        }
        niceId
        product {
          displayName
          id
          name
        }
        totalLoanAmountInCents
        updatedAtUtc
      }
    }
  }
`;

export { deleteLoanApplicationMutation, retrievePaginatedLoanApplicationsForLoggedInUsersEntityQuery };
