import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import equals from 'validator/lib/equals';
import isLength from 'validator/lib/isLength';
import { z } from 'zod';

const PASSWORD_LENGTH = 8; // TODO: Get this value from the server

const PASSWORD_LENGTH_ERROR_MESSAGE = `Password must be at least ${PASSWORD_LENGTH} characters long`;

const auth0PasswordValidation = z
  .string()
  .min(PASSWORD_LENGTH, PASSWORD_LENGTH_ERROR_MESSAGE)
  .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
  .regex(/[a-z]/, 'Password must contain at least one lowercase letter')
  .regex(/\d/, 'Password must contain at least one digit')
  .regex(/[!@#$%^&*()\-=_+[\]{}|;':",.<>/?]/, 'Password must contain at least one special character');

const passwordValidation = z
  .string()
  .min(1, {
    message: 'Please enter a password',
  })
  .refine(
    (value) =>
      isLength(value, {
        min: PASSWORD_LENGTH,
      }),
    {
      message: PASSWORD_LENGTH_ERROR_MESSAGE,
    },
  );

const createChangePasswordFormZodResolver = () => {
  return zodResolver(
    z
      .object({
        confirmNewPassword: auth0PasswordValidation,
        currentPassword: passwordValidation,
        newPassword: auth0PasswordValidation,
      })
      .refine(({ confirmNewPassword, newPassword }) => equals(confirmNewPassword, newPassword), {
        message: `Your new password and confirm new password don't match. Please try again.`,
        path: ['confirmNewPassword'],
      }),
  );
};

export { createChangePasswordFormZodResolver };
