import { FC, ReactElement, useEffect, useState } from 'react';

import { LoanApplicationCustomerStatus } from 'graphql-library';

import { LoanApplicationConfirmationModal } from '../LoanApplicationConfirmationModal';
import { LoanApplicationConfirmInvoiceModal } from '../LoanApplicationConfirmInvoiceModal';
import { LoanApplicationSelectDirectDebitAccountModal } from '../LoanApplicationSelectDirectDebitAccountModal';
import { LoanApplicationSelectRepaymentPlanModal } from '../LoanApplicationSelectRepaymentPlanModal';
import { LoanApplicationSubmittedModal } from '../LoanApplicationSubmittedModal';
import { LoanApplicationUploadInvoiceModal } from '../LoanApplicationUploadInvoiceModal';

import { FinanceInvoiceFlowProps } from './FinanceInvoiceFlow.types';

const FinanceInvoiceFlow: FC<FinanceInvoiceFlowProps> = ({
  isModalOpen,
  loanApplication: { customerStatus, id, niceId },
  onModalClose,
  onModalContinue,
}) => {
  const [component, setComponent] = useState<ReactElement>(<></>);

  const handleLoanApplicationConfirmationModalClose = () => {
    onModalClose();
  };

  const handleLoanApplicationSubmittedModalClose = () => {
    onModalClose();
  };

  const handleSelectRepaymentPlanModalClose = () => {
    onModalClose();
  };

  const handleSelectDirectDebitAccountModalClose = () => {
    onModalClose();
  };

  const handleUploadInvoiceModalClose = () => {
    onModalClose();
  };

  const handleSupplierDetailModalClose = () => {
    onModalClose();
  };

  useEffect(() => {
    switch (customerStatus) {
      case 'Confirm': {
        setComponent(
          <LoanApplicationConfirmationModal
            isOpen={isModalOpen && customerStatus === LoanApplicationCustomerStatus.Confirm}
            loanApplication={{
              id,
              niceId,
            }}
            onClose={handleLoanApplicationConfirmationModalClose}
            onContinue={onModalContinue}
          />,
        );

        break;
      }

      case 'ConfirmInvoice': {
        setComponent(
          <LoanApplicationConfirmInvoiceModal
            isOpen={isModalOpen && customerStatus === LoanApplicationCustomerStatus.ConfirmInvoice}
            loanApplication={{
              id,
              niceId,
            }}
            onClose={handleSupplierDetailModalClose}
            onContinue={onModalContinue}
          />,
        );

        break;
      }

      case 'SelectDirectDebitAccount': {
        setComponent(
          <LoanApplicationSelectDirectDebitAccountModal
            isOpen={isModalOpen && customerStatus === LoanApplicationCustomerStatus.SelectDirectDebitAccount}
            loanApplication={{
              id,
              niceId,
            }}
            onClose={handleSelectDirectDebitAccountModalClose}
            onContinue={onModalContinue}
          />,
        );

        break;
      }

      case 'SelectRepaymentPlan': {
        setComponent(
          <LoanApplicationSelectRepaymentPlanModal
            isOpen={isModalOpen && customerStatus === LoanApplicationCustomerStatus.SelectRepaymentPlan}
            loanApplication={{
              id,
              niceId,
            }}
            onClose={handleSelectRepaymentPlanModalClose}
            onContinue={onModalContinue}
          />,
        );

        break;
      }

      case 'Submitted': {
        setComponent(
          <LoanApplicationSubmittedModal
            isOpen={isModalOpen && customerStatus === LoanApplicationCustomerStatus.Submitted}
            onClose={handleLoanApplicationSubmittedModalClose}
          />,
        );

        break;
      }

      case 'UploadInvoice': {
        setComponent(
          <LoanApplicationUploadInvoiceModal
            isOpen={isModalOpen && customerStatus === LoanApplicationCustomerStatus.UploadInvoice}
            loanApplication={{
              id,
              niceId,
            }}
            onClose={handleUploadInvoiceModalClose}
            onContinue={onModalContinue}
          />,
        );

        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerStatus, isModalOpen]);

  return component;
};

export { FinanceInvoiceFlow };
