import { FC, useEffect, useState } from 'react';

import { currency, dayjs, useAuthentication } from 'common';
import {
  LoanApplicationCustomerStatus,
  useRetrieveLoanApplicationForLoanApplicationConfirmInvoiceLazyQuery,
  useUpdateLoanApplicationCustomerStatusMutation,
  useUpdateLoanApplicationForLoanApplicationConfirmInvoiceMutation,
} from 'graphql-library';
import { BizPayLoader, BizPayModal, ErrorAlert, PageHeading, Stack, useBizPayNotification } from 'ui';

import { LoanApplicationConfirmInvoiceForm } from '../LoanApplicationConfirmInvoiceForm';
import { LoanApplicationDetails } from '../LoanApplicationDetails';

import {
  LoanApplicationForLoanApplicationConfirmInvoiceInvoice,
  LoanApplicationForLoanApplicationConfirmInvoiceInvoiceSupplier,
  LoanApplicationConfirmInvoiceModalProps,
} from './LoanApplicationConfirmInvoiceModal.types';

import { useSignOut } from '../../hooks';

const LoanApplicationConfirmInvoiceModal: FC<LoanApplicationConfirmInvoiceModalProps> = ({
  isOpen,
  loanApplication: { id: loanApplicationId, niceId: loanApplicationNiceId },
  onClose,
  onContinue,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasRetrievedLoanApplication, setHasRetrievedLoanApplication] = useState<boolean>(false);
  const [invoiceDateDueAtUtc, setInvoiceDateDueAtUtc] =
    useState<NonNullable<LoanApplicationForLoanApplicationConfirmInvoiceInvoice>['dateDueAtUtc']>();
  const [invoiceDocumentSignedUrl, setInvoiceDocumentSignedUrl] = useState<string>();
  const [supplier, setSupplier] = useState<LoanApplicationForLoanApplicationConfirmInvoiceInvoiceSupplier | null>();
  const [totalInvoiceAmountInCents, setTotalInvoiceAmountInCents] =
    useState<NonNullable<LoanApplicationForLoanApplicationConfirmInvoiceInvoice>['totalAmountInCents']>();

  const [executeUpdateLoanApplicationCustomerStatusMutation, { loading: isUpdateLoanApplicationCustomerStatusLoading }] =
    useUpdateLoanApplicationCustomerStatusMutation({
      onCompleted: ({ updateLoanApplicationCustomerStatus: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message: `Unable to update your loan application - ${message}`,
        });
      },
    });

  const [
    executeRetrieveLoanApplicationForLoanApplicationConfirmInvoiceQuery,
    { loading: isRetrieveLoanApplicationForLoanApplicationConfirmInvoiceLoading },
  ] = useRetrieveLoanApplicationForLoanApplicationConfirmInvoiceLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanApplication: { invoice: returnedInvoice } }) => {
      if (!returnedInvoice) {
        return;
      }

      const { dateDueAtUtc, document, supplier, totalAmountInCents } = returnedInvoice;

      setHasRetrievedLoanApplication(true);
      setInvoiceDateDueAtUtc(dateDueAtUtc);
      setInvoiceDocumentSignedUrl(document.signedUrl ?? undefined);
      setSupplier(supplier);
      setTotalInvoiceAmountInCents(totalAmountInCents);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your loan application',
      });
    },
  });

  const [
    executeUpdateLoanApplicationForLoanApplicationConfirmInvoiceMutationMutation,
    { loading: isUpdateLoanApplicationForLoanApplicationConfirmInvoiceLoading },
  ] = useUpdateLoanApplicationForLoanApplicationConfirmInvoiceMutation({
    onCompleted: ({ updateLoanApplicationSupplier: { customerStatus } }) => {
      onContinue(customerStatus);
    },
    onError: ({ message }) => {
      setErrorMessage(message);
    },
  });

  const handleClose = () => {
    setErrorMessage(undefined);
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!isOpen) {
      return;
    }

    executeRetrieveLoanApplicationForLoanApplicationConfirmInvoiceQuery({
      variables: {
        id: loanApplicationId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isOpen]);

  const isLoading =
    isRetrieveLoanApplicationForLoanApplicationConfirmInvoiceLoading || isUpdateLoanApplicationForLoanApplicationConfirmInvoiceLoading;

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      closeOnEscape={!isLoading}
      opened={isOpen}
      size={875}
      subTitle={
        <LoanApplicationDetails invoiceDocumentSignedUrl={invoiceDocumentSignedUrl} loanApplicationNiceId={loanApplicationNiceId} />
      }
      title={
        <PageHeading
          flexContainerProps={{
            mb: 0,
          }}
          heading="Confirm your invoice"
          size="h4"
        />
      }
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      <Stack h={500} mt="md">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isUpdateLoanApplicationCustomerStatusLoading && (
              <BizPayLoader message="Returning to the upload invoice step, please wait..." />
            )}

            {isUpdateLoanApplicationForLoanApplicationConfirmInvoiceLoading && (
              <BizPayLoader message="Updating your loan application, please wait..." />
            )}
          </Stack>
        ) : (
          <>
            {hasRetrievedLoanApplication && (
              <>
                <LoanApplicationConfirmInvoiceForm
                  isLoading={isLoading}
                  values={{
                    invoiceAmountInDollars: totalInvoiceAmountInCents
                      ? currency(totalInvoiceAmountInCents / 100).format({
                          symbol: '',
                        })
                      : '',
                    invoiceDateDueAtUtc: invoiceDateDueAtUtc ? dayjs(invoiceDateDueAtUtc).toDate() : undefined,
                    supplierAbn: supplier?.abn ?? '',
                    supplierEmail: supplier?.email ?? '',
                    supplierPhone: supplier?.phone ?? '',
                  }}
                  onBackButtonClick={() => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeUpdateLoanApplicationCustomerStatusMutation({
                      variables: {
                        customerStatus: LoanApplicationCustomerStatus.UploadInvoice,
                        id: loanApplicationId,
                      },
                    });
                  }}
                  onSubmit={({ invoiceAmountInCents, invoiceDateDueAtUtc, supplierAbn, supplierEmail, supplierPhone }) => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    setInvoiceDateDueAtUtc(invoiceDateDueAtUtc);

                    setSupplier((previousSupplier) => {
                      if (!previousSupplier) {
                        return;
                      }

                      return {
                        ...previousSupplier,
                        abn: supplierAbn,
                        email: supplierEmail,
                        phone: supplierPhone,
                      };
                    });

                    setTotalInvoiceAmountInCents(invoiceAmountInCents);

                    executeUpdateLoanApplicationForLoanApplicationConfirmInvoiceMutationMutation({
                      variables: {
                        id: loanApplicationId,
                        input: {
                          invoiceAmountInCents,
                          invoiceDateDueAtUtc: invoiceDateDueAtUtc ? dayjs(invoiceDateDueAtUtc).toISOString() : undefined,
                          supplierAbn,
                          supplierEmail,
                          supplierPhone,
                        },
                      },
                    });
                  }}
                />

                {errorMessage && (
                  <ErrorAlert mt="md" title="Confirm your invoice error" w="100%">
                    {errorMessage}
                  </ErrorAlert>
                )}
              </>
            )}
          </>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { LoanApplicationConfirmInvoiceModal };
