import { z, zodResolver } from 'ui';

const loanApplicationSelectRepaymentPlanFormZodResolver = () => {
  return zodResolver(
    z.object({
      productId: z.string().min(1, {
        message: 'Please select a repayment plan',
      }),
    }),
  );
};

export { loanApplicationSelectRepaymentPlanFormZodResolver };
