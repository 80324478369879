import { FC } from 'react';

import { BizPayPrimaryButton } from 'ui';

import { AddDirectDebitAccountButtonProps } from './AddDirectDebitAccountButton.types';

const AddDirectDebitAccountButton: FC<AddDirectDebitAccountButtonProps> = ({ onClick, ...otherProps }) => {
  return (
    <BizPayPrimaryButton onClick={onClick} {...otherProps}>
      Add direct debit account
    </BizPayPrimaryButton>
  );
};

export { AddDirectDebitAccountButton };
