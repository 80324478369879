import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanApplicationForLoanApplicationConfirmInvoiceQuery: DocumentNode = gql`
  query retrieveLoanApplicationForLoanApplicationConfirmInvoice($id: ID!) {
    retrieveLoanApplication(id: $id) {
      id
      invoice {
        dateDueAtUtc
        document {
          id
          signedUrl
        }
        id
        supplier {
          abn
          email
          id
          phone
        }
        totalAmountInCents
      }
    }
  }
`;

const updateLoanApplicationForLoanApplicationConfirmInvoiceMutation: DocumentNode = gql`
  mutation updateLoanApplicationForLoanApplicationConfirmInvoice($id: ID!, $input: UpdateLoanApplicationConfirmInvoiceInput!) {
    updateLoanApplicationSupplier(id: $id, input: $input) {
      customerStatus
      id
    }
  }
`;

export { retrieveLoanApplicationForLoanApplicationConfirmInvoiceQuery, updateLoanApplicationForLoanApplicationConfirmInvoiceMutation };
