import { z, zodResolver } from 'ui';

const loanApplicationConfirmationFormZodResolver = () => {
  return zodResolver(
    z.object({
      hasAgreedToPaymentTermsAndConditions: z.boolean().refine((value) => value),
    }),
  );
};

export { loanApplicationConfirmationFormZodResolver };
