import { FC } from 'react';

import { BaseLink } from '../BaseLink';

import { InternalLinkProps } from './InternalLink.types';

const InternalLink: FC<InternalLinkProps> = ({ children, ...otherProps }) => {
  return <BaseLink {...otherProps}>{children}</BaseLink>;
};

export { InternalLink };
