import { FC } from 'react';

import { Group, Stack, Text } from 'ui';

import { ExternalLink } from '../ExternalLink';

import { LoanApplicationDetailsProps } from './LoanApplicationDetails.types';

const LoanApplicationDetails: FC<LoanApplicationDetailsProps> = ({ invoiceDocumentSignedUrl, loanApplicationNiceId }) => {
  return (
    <Stack>
      <Group>
        <Text weight="bold">Loan application ID:&nbsp;</Text>
        <Text>{loanApplicationNiceId}</Text>
      </Group>

      <Group>
        <Text weight="bold">Invoice:</Text>
        <Text ml={3} mr={5}>
          {invoiceDocumentSignedUrl ? (
            <ExternalLink
              linkProps={{
                href: new URL(invoiceDocumentSignedUrl),
              }}
              textProps={{
                weight: 'bold',
              }}
            >
              View invoice
            </ExternalLink>
          ) : (
            'Will be viewable pending successful upload & analysis of your invoice'
          )}
        </Text>
      </Group>
    </Stack>
  );
};

export { LoanApplicationDetails };
