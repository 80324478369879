import { DocumentNode, gql } from 'graphql-library';

const deleteDirectDebitAccountForLoggedInUserMutation: DocumentNode = gql`
  mutation deleteDirectDebitAccountForLoggedInUser($id: ID!) {
    deleteDirectDebitAccountForLoggedInUser(id: $id) {
      id
    }
  }
`;

const retrievePaginatedDirectDebitAccountsForLoggedInUsersEntityQuery: DocumentNode = gql`
  query retrievePaginatedDirectDebitAccountsForLoggedInUsersEntity($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedDirectDebitAccountsForLoggedInUsersEntity(pagination: $pagination, sort: $sort) {
      count
      data {
        accountName
        accountNumber
        alias
        bsb
        createdAtUtc
        id
        isDefault
        financialInstitutionName
        status
      }
    }
  }
`;

export { deleteDirectDebitAccountForLoggedInUserMutation, retrievePaginatedDirectDebitAccountsForLoggedInUsersEntityQuery };
