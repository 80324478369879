import { UserDropdownMenuItemProps, BizPayNavigationLinkProps, Page } from 'ui';

export enum ProtectedRoute {
  BankAccounts = '/bank-accounts',
  BusinessDetails = '/business-details',
  Dashboard = '/dashboard',
  Directors = '/directors',
  Invoices = '/invoices',
  LoanApplications = '/loan-applications',
  Loans = '/loans',
  Notifications = '/notifications',
  Profile = '/profile',
  SignOut = '/sign-out',
}

export enum PublicRoute {
  SignIn = '/sign-in',
}

export const PROTECTED_SIDE_MENU_NAVIGATION_ROUTES = [
  ProtectedRoute.Dashboard,
  ProtectedRoute.BusinessDetails,
  ProtectedRoute.LoanApplications,
  ProtectedRoute.Loans,
] as const;

export const PROTECTED_USER_DROPDOWN_MENU_NAVIGATION_ROUTES = [ProtectedRoute.Profile] as const;

export type ProtectedSideMenuNavigationRoutes = (typeof PROTECTED_SIDE_MENU_NAVIGATION_ROUTES)[number];

export type ProtectedSideMenuNavigationRoutesPage = Record<ProtectedSideMenuNavigationRoutes, Page>;

export type ProtectedUserDropdownMenuNavigationRoutes = (typeof PROTECTED_USER_DROPDOWN_MENU_NAVIGATION_ROUTES)[number];

export type ProtectedUserDropdownMenuNavigationRoutesPage = Record<ProtectedUserDropdownMenuNavigationRoutes, Page>;

export interface UseNavigationRoutes {
  buildNavigationBarLinks: (currentRoute: string) => BizPayNavigationLinkProps[];
  buildUserDropdownMenuNavigationLinks: () => UserDropdownMenuItemProps[];
  findPageByRoute: (route: string) => Page;
}
