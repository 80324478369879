import { FC } from 'react';

import { Checkbox, Divider, Grid, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';
import { TextInputRightSection } from '../TextInputRightSection';

import { createDirectDebitAccountFormZodResolver } from './DirectDebitAccountForm.helpers';
import { DirectDebitAccountFormData, DirectDebitAccountFormProps } from './DirectDebitAccountForm.types';

const DirectDebitAccountForm: FC<DirectDebitAccountFormProps<DirectDebitAccountFormData>> = ({
  initialDefaultValues,
  isLoading,
  onSubmit,
  submitButtonText = 'Save',
}) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<DirectDebitAccountFormData>({
    defaultValues: initialDefaultValues,
    mode: 'onChange',
    resolver: createDirectDebitAccountFormZodResolver(),
  });

  const {
    accountName: accountNameError,
    accountNumber: accountNumberError,
    alias: aliasError,
    bsb: bsbError,
    financialInstitutionName: financialInstitutionNameError,
  } = errors;

  return (
    <form
      id="direct-debit-account-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack h="100%">
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              {...register('bsb')}
              disabled={isLoading}
              error={bsbError ? <ErrorMessage message={bsbError.message} /> : null}
              label="BSB"
              placeholder="Your 6 digit BSB (no dashes or spaces)"
              rightSection={<TextInputRightSection error={bsbError} value={watch('bsb')} />}
              size="md"
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('financialInstitutionName')}
              disabled={isLoading}
              error={financialInstitutionNameError ? <ErrorMessage message={financialInstitutionNameError.message} /> : null}
              label="Financial institution name"
              placeholder="Commonwealth Bank"
              rightSection={<TextInputRightSection error={financialInstitutionNameError} value={watch('financialInstitutionName')} />}
              size="md"
            />
          </Grid.Col>
        </Grid>

        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              {...register('accountNumber')}
              disabled={isLoading}
              error={accountNumberError ? <ErrorMessage message={accountNumberError.message} /> : null}
              label="Account number"
              placeholder="Your 6-10 digit account number (no dashes or spaces)"
              rightSection={<TextInputRightSection error={accountNumberError} value={watch('accountNumber')} />}
              size="md"
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('alias')}
              color="blue"
              disabled={isLoading}
              error={aliasError ? <ErrorMessage message={aliasError.message} /> : null}
              label="Alias"
              placeholder="Use an alias to easily identify your accounts"
              rightSection={<TextInputRightSection error={aliasError} value={watch('alias')} />}
              size="md"
            />
          </Grid.Col>
        </Grid>

        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              {...register('accountName')}
              disabled={isLoading}
              error={accountNameError ? <ErrorMessage message={accountNameError.message} /> : null}
              label="Account name"
              placeholder="Your account name"
              rightSection={<TextInputRightSection error={accountNameError} value={watch('accountName')} />}
              size="md"
            />
          </Grid.Col>

          <Grid.Col span="auto"></Grid.Col>
        </Grid>

        <Divider mt="xl" />

        <Grid gutter="sm" mt="md">
          <Grid.Col span="auto">
            <Checkbox
              {...register('hasAuthorisedDirectDebitAccount')}
              disabled={isLoading}
              label={
                <Text
                  sx={({ colors }) => ({
                    color: colors['bizpay-light-gray'][7],
                    fontSize: 10,
                  })}
                >
                  By checking this box, you are confirming that you have read, understood and agreed to the terms of the Direct Debit
                  Request Service Agreement and authorise BizPay Group Limited, via SR Global Solutions Pty Ltd (Debit User ID No. 397351),
                  trading as Merchant Warrior, to initiate direct debits from your nominated bank account as per the terms of the agreement.
                  You understand that the debit amount will be determined by the Business or Merchant Warrior and may vary. You also confirm
                  that you are authorised to operate the nominated account.
                </Text>
              }
            />
          </Grid.Col>
        </Grid>
      </Stack>

      <Group mt="xl" position="right" w="100%">
        <BizPayPrimaryButton disabled={!isValid} type="submit">
          {submitButtonText}
        </BizPayPrimaryButton>
      </Group>
    </form>
  );
};

export { DirectDebitAccountForm };
