import { FC, useEffect, useState } from 'react';

import { kebabCase, useAuthentication } from 'common';
import { useRetrieveLoanForLoggedInUsersEntityLazyQuery } from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  Anchor,
  BizPayCustomerPortalDynamicNextJsTitle,
  BizPayLoader,
  BizPayOutlinedButton,
  Breadcrumbs,
  DefaultPageLayout,
  Flex,
  FullHeightContainer,
  Group,
  PageHeading,
  Stack,
  Tabs,
  TabsValue,
  Text,
  useBizPayNotification,
} from 'ui';

import { LoanPageLoanInfoTab } from '../LoanPageLoanInfoTab';

import { LOAN_PAGE_CONSTANTS } from './LoanPage.constants';
import { LoanForCustomerPortalLoanPage, LoanPageProps } from './LoanPage.types';

import { ProtectedRoute, useNavigationRoutes, useSignOut } from '../../hooks';

const { tabs } = LOAN_PAGE_CONSTANTS;

const LoanPage: FC<LoanPageProps> = ({ id }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { findPageByRoute } = useNavigationRoutes();
  const { push, route } = useRouter();
  const { signOut } = useSignOut();

  const [invoiceDocument, setInvoiceDocument] = useState<LoanForCustomerPortalLoanPage['invoiceDocument']>();
  const [loan, setLoan] = useState<LoanForCustomerPortalLoanPage>();
  const [paymentAgreementDocument, setPaymentAgreementDocument] = useState<LoanForCustomerPortalLoanPage['paymentAgreementDocument']>();
  const [tabIdValue, setTabIdValue] = useState<TabsValue>(tabs.config[0].id);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [executeRetrieveLoanForLoggedInUsersEntityQuery, { loading: isRetrieveLoanLoading }] =
    useRetrieveLoanForLoggedInUsersEntityLazyQuery({
      onCompleted: ({ retrieveLoanForLoggedInUsersEntity: returnedLoan }) => {
        const { invoiceDocument, paymentAgreementDocument } = returnedLoan;

        setInvoiceDocument(invoiceDocument);
        setLoan(returnedLoan);
        setPaymentAgreementDocument(paymentAgreementDocument);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve loan',
        });
      },
    });

  const handleBreadcrumbItemClick = (route: string) => {
    push(route);
  };

  const handleTabChange = (value: TabsValue) => {
    setTabIdValue(value);
    setTabIndex(tabs.config.findIndex(({ id }) => id === value));
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLoanForLoggedInUsersEntityQuery({
      variables: {
        id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle={`${title} - Loan id: ${id}`} />

      {isRetrieveLoanLoading ? (
        <Stack align="center" h="100%" justify="center">
          <BizPayLoader message="Retrieving loan, please wait..." />
        </Stack>
      ) : (
        <>
          {loan && (
            <DefaultPageLayout
              mainComponent={
                <FullHeightContainer>
                  <Tabs value={tabIdValue} w="100%" onTabChange={handleTabChange}>
                    <Flex id="tabs-container" w="100%">
                      <Flex id="tabs-list-container" w="100%">
                        <Tabs.List w="100%">
                          {tabs.config.map(({ id, title }) => {
                            return (
                              <Tabs.Tab key={`tab-${id}`} value={id}>
                                {title}
                              </Tabs.Tab>
                            );
                          })}
                        </Tabs.List>
                      </Flex>

                      <Flex id="tabs-buttons-container" ml="xs">
                        {paymentAgreementDocument && (
                          <Anchor href={paymentAgreementDocument.signedUrl} target="_blank">
                            <BizPayOutlinedButton>Download payment agreement</BizPayOutlinedButton>
                          </Anchor>
                        )}

                        {invoiceDocument && (
                          <Anchor href={invoiceDocument.signedUrl} ml="xs" target="_blank">
                            <BizPayOutlinedButton text-align="right">Open invoice</BizPayOutlinedButton>
                          </Anchor>
                        )}
                      </Flex>
                    </Flex>

                    <Tabs.Panel
                      mt="xs"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                      value={tabs.config[tabIndex].id}
                    >
                      {tabs.config[tabIndex].id === tabs.ids.loanInfo && <LoanPageLoanInfoTab loan={loan} />}
                    </Tabs.Panel>
                  </Tabs>
                </FullHeightContainer>
              }
              pageHeadingComponent={
                <Group align="center" mb="md" position="center" w="100%">
                  <Stack justify="center" w="100%">
                    <PageHeading
                      flexContainerProps={{
                        mb: 0,
                      }}
                      heading={title}
                      size="h4"
                    />

                    <Group align="center" ml={1} mt={5}>
                      <Breadcrumbs separator=">">
                        {[
                          {
                            label: 'Loans',
                            route: ProtectedRoute.Loans,
                          },
                          {
                            label: (
                              <Group noWrap>
                                <Text weight="bold">Loan ID:</Text>
                                <Text> {loan.niceId}</Text>
                              </Group>
                            ),
                          },
                        ].map(({ label, route: breadcrumbRoute }) => {
                          const key = kebabCase(breadcrumbRoute);

                          return breadcrumbRoute ? (
                            <Anchor
                              key={kebabCase(breadcrumbRoute)}
                              weight="bold"
                              onClick={() => handleBreadcrumbItemClick(breadcrumbRoute)}
                            >
                              {label}
                            </Anchor>
                          ) : (
                            <Text key={key}>{label}</Text>
                          );
                        })}
                      </Breadcrumbs>
                    </Group>
                  </Stack>
                </Group>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export { LoanPage };
