import { FC, useState } from 'react';

import { LoanApplication, LoanApplicationCustomerStatus } from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayCustomerPortalDynamicNextJsTitle, FullHeightContainer, FullPageLayout, PageHeading } from 'ui';

import { FinanceInvoiceFlow } from '../FinanceInvoiceFlow';
import { LoanApplicationsTable } from '../LoanApplicationsTable';

import { useNavigationRoutes } from '../../hooks';

const LoanApplicationsPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loanApplicationCustomerStatus, setLoanApplicationCustomerStatus] = useState<LoanApplicationCustomerStatus>();
  const [loanApplicationId, setLoanApplicationId] = useState<string>();
  const [loanApplicationNiceId, setLoanApplicationNiceId] = useState<number>();
  const [shouldRefetchLoanApplicationData, setShouldRefetchLoanApplicationData] = useState<boolean>(false);

  const handleContinueLoanApplication = (
    customerStatus: LoanApplication['customerStatus'],
    id: LoanApplication['id'],
    niceId: LoanApplication['niceId'],
  ) => {
    setIsModalOpen(true);
    setLoanApplicationCustomerStatus(customerStatus);
    setLoanApplicationId(id);
    setLoanApplicationNiceId(niceId);
  };

  const handleFinanceInvoiceFlowModalContinue = (customerStatus: LoanApplication['customerStatus']) => {
    setLoanApplicationCustomerStatus(customerStatus);
    setShouldRefetchLoanApplicationData(true);
  };

  const handleModalClose = () => {
    setIsModalOpen((previousValue) => !previousValue);
  };

  const handleRefetchLoanApplicationDataCompleted = () => {
    setShouldRefetchLoanApplicationData(false);
  };

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      {loanApplicationId && loanApplicationNiceId && loanApplicationCustomerStatus && (
        <FinanceInvoiceFlow
          isModalOpen={isModalOpen}
          loanApplication={{
            customerStatus: loanApplicationCustomerStatus,
            id: loanApplicationId,
            niceId: loanApplicationNiceId,
          }}
          onModalClose={handleModalClose}
          onModalContinue={handleFinanceInvoiceFlowModalContinue}
        />
      )}

      <FullPageLayout
        mainComponent={
          <FullHeightContainer>
            <LoanApplicationsTable
              paginationOptions={{
                isPaginationEnabled: true,
              }}
              shouldRefetchData={shouldRefetchLoanApplicationData}
              onContinueLoanApplication={handleContinueLoanApplication}
              onRefetchDataComplete={handleRefetchLoanApplicationDataCompleted}
            />
          </FullHeightContainer>
        }
        pageHeadingComponent={<PageHeading heading={title} size="h4" />}
      />
    </>
  );
};

export { LoanApplicationsPage };
