import { z, zodResolver } from 'ui';

const LoanApplicationSelectDirectDebitAccountFormZodResolver = () => {
  return zodResolver(
    z.object({
      directDebitAccountId: z.string().min(1, {
        message: 'Please select a direct debit account',
      }),
    }),
  );
};

export { LoanApplicationSelectDirectDebitAccountFormZodResolver };
