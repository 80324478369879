import { DocumentNode, gql } from 'graphql-library';

const customerPortalSignInQuery: DocumentNode = gql`
  query customerPortalSignIn($email: String!, $password: String!) {
    customerPortalSignIn(input: { email: $email, password: $password }) {
      accessToken
      refreshToken
    }
  }
`;

const resetUserPasswordMutation: DocumentNode = gql`
  mutation resetUserPassword($email: String!) {
    resetUserPassword(email: $email)
  }
`;

export { customerPortalSignInQuery, resetUserPasswordMutation };
