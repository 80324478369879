import { sentenceCase } from 'common';
import { IconBriefcase, IconCashBanknote, IconDashboard, IconFileText, IconUser } from 'ui';

import {
  ProtectedUserDropdownMenuNavigationRoutesPage,
  ProtectedSideMenuNavigationRoutesPage,
  ProtectedRoute,
} from './useNavigationRoutes.types';

const USE_NAVIGATION_ROUTE_CONSTANTS = Object.freeze({
  sideMenuRoutes: Object.freeze<ProtectedSideMenuNavigationRoutesPage>({
    [ProtectedRoute.Dashboard]: {
      menuIcon: <IconDashboard size={20} />,
      title: sentenceCase(ProtectedRoute.Dashboard),
    },
    [ProtectedRoute.BusinessDetails]: {
      menuIcon: <IconBriefcase size={20} />,
      title: sentenceCase(ProtectedRoute.BusinessDetails),
    },
    [ProtectedRoute.Loans]: {
      menuIcon: <IconCashBanknote size={20} />,
      title: sentenceCase(ProtectedRoute.Loans),
    },
    [ProtectedRoute.LoanApplications]: {
      menuIcon: <IconFileText size={20} />,
      title: sentenceCase(ProtectedRoute.LoanApplications),
    },
  }),
  userDropdownMenuRoutes: Object.freeze<ProtectedUserDropdownMenuNavigationRoutesPage>({
    [ProtectedRoute.Profile]: {
      menuIcon: <IconUser size={20} />,
      title: sentenceCase(ProtectedRoute.Profile),
    },
  }),
});

export { USE_NAVIGATION_ROUTE_CONSTANTS };
