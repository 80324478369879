import { FC, useEffect } from 'react';

import { Loader, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';

import { createSignInFormZodResolver } from './SignInForm.helpers';
import { SignInFormData, SignInFormProps } from './SignInForm.types';

const SignInForm: FC<SignInFormProps> = ({ forgotPasswordLinkComponent, isLoading = false, signUpLinkComponent, onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setFocus,
    setValue,
    trigger,
  } = useForm<SignInFormData>({
    mode: 'onChange',
    resolver: createSignInFormZodResolver(),
  });

  const handleEmailChange = (email: string) => {
    setValue('email', email);
    trigger('email');
  };

  const handlePasswordChange = (password: string) => {
    setValue('password', password);
    trigger('password');
  };

  useEffect(() => {
    setFocus('email');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { email: emailError, password: passwordError } = errors;

  return (
    <>
      <form id="sign-in-form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('email')}
          data-testid="e2e-sign-in-form-email"
          disabled={isLoading}
          error={emailError ? <ErrorMessage message={emailError.message} /> : null}
          label="Email"
          placeholder="you@yourdomain.com"
          size="md"
          onChange={({ target: { value } }) => handleEmailChange(value)}
        />

        <PasswordInput
          {...register('password')}
          data-testid="e2e-sign-in-form-password"
          disabled={isLoading}
          error={passwordError ? <ErrorMessage message={passwordError.message} /> : null}
          label="Password"
          mt="xs"
          placeholder="Your password"
          size="md"
          onChange={({ target: { value } }) => handlePasswordChange(value)}
        />

        <BizPayPrimaryButton
          data-testid="e2e-sign-in-form-button"
          disabled={isLoading || !isValid}
          mb="3rem"
          mt="3rem"
          type="submit"
          fullWidth
        >
          {isLoading ? <Loader size="xs" /> : 'Sign in'}
        </BizPayPrimaryButton>

        <Stack align="center">
          {signUpLinkComponent}
          {forgotPasswordLinkComponent}
        </Stack>
      </form>
    </>
  );
};

export { SignInForm };
