import { sentenceCase } from 'common';

import { BusinessDetailsPageTabsConfig } from './BusinessDetailsPage.types';

const BUSINESS_INFO_TAB_ID = 'business-info';
const DIRECT_DEBIT_ACCOUNTS_TAB_ID = 'direct-debit-accounts';
const DOCUMENTS_TAB_ID = 'documents';

const BUSINESS_DETAILS_PAGE_TABS_CONFIG: BusinessDetailsPageTabsConfig[] = [
  {
    id: BUSINESS_INFO_TAB_ID,
    title: sentenceCase(BUSINESS_INFO_TAB_ID),
  },
  {
    id: DIRECT_DEBIT_ACCOUNTS_TAB_ID,
    title: sentenceCase(DIRECT_DEBIT_ACCOUNTS_TAB_ID),
  },
  {
    id: DOCUMENTS_TAB_ID,
    title: sentenceCase(DOCUMENTS_TAB_ID),
  },
];

const BUSINESS_DETAILS_PAGE_CONSTANTS = Object.freeze({
  tabs: {
    config: BUSINESS_DETAILS_PAGE_TABS_CONFIG,
    ids: {
      businessInfo: BUSINESS_INFO_TAB_ID,
      directDebitAccounts: DIRECT_DEBIT_ACCOUNTS_TAB_ID,
      documents: DOCUMENTS_TAB_ID,
    },
  },
});

export { BUSINESS_DETAILS_PAGE_CONSTANTS };
