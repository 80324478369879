import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import isEmail from 'validator/lib/isEmail';
import { z } from 'zod';

const createForgotPasswordFormZodResolver = () => {
  return zodResolver(
    z.object({
      email: z
        .string()
        .min(1, {
          message: 'Please enter your email',
        })
        .refine((value) => isEmail(value), {
          message: 'Please enter a valid email',
        }),
    }),
  );
};

export { createForgotPasswordFormZodResolver };
