import { abnZodEffect, dueDateZodEffect, emailZodEffect, invoiceAmountInDollarsZodEffect, z, zodResolver } from 'ui';

const loanApplicationConfirmInvoiceFormZodResolver = () => {
  return zodResolver(
    z.object({
      invoiceAmountInDollars: invoiceAmountInDollarsZodEffect,
      invoiceDateDueAtUtc: dueDateZodEffect,
      supplierAbn: abnZodEffect,
      supplierEmail: emailZodEffect,
      supplierPhone: z.string().optional(),
    }),
  );
};

export { loanApplicationConfirmInvoiceFormZodResolver };
