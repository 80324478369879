import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedDocumentsForLoggedInUsersEntityQuery: DocumentNode = gql`
  query retrievePaginatedDocumentsForLoggedInUsersEntity($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedDocumentsForLoggedInUsersEntity(pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        id
        originalFileName
        signedUrl
        type
      }
    }
  }
`;

export { retrievePaginatedDocumentsForLoggedInUsersEntityQuery };
