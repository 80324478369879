import { FC, useEffect } from 'react';

import { Grid, Group, Loader, PasswordInput } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';

import { createChangePasswordFormZodResolver } from './ChangePasswordForm.helpers';
import { ChangePasswordFormData, ChangePasswordFormProps } from './ChangePasswordForm.types';

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ isLoading = false, onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setFocus,
  } = useForm<ChangePasswordFormData>({
    mode: 'onChange',
    resolver: createChangePasswordFormZodResolver(),
  });

  useEffect(() => {
    setFocus('currentPassword');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { confirmNewPassword: confirmNewPasswordError, currentPassword: currentPasswordError, newPassword: newPasswordError } = errors;

  return (
    <form id="change-password-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid gutter="sm" mt="sm">
        <Grid.Col span="auto">
          <PasswordInput
            {...register('currentPassword')}
            error={currentPasswordError ? <ErrorMessage message={currentPasswordError.message} /> : null}
            label="Current password"
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <PasswordInput
            {...register('newPassword')}
            error={newPasswordError ? <ErrorMessage message={newPasswordError.message} /> : null}
            label="New password"
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <PasswordInput
            {...register('confirmNewPassword')}
            error={confirmNewPasswordError ? <ErrorMessage message={confirmNewPasswordError.message} /> : null}
            label="Confirm new password"
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Group mt="xl" position="right" noWrap>
        <BizPayPrimaryButton disabled={isLoading || !isValid} ml="sm" size="md" type="submit">
          {isLoading ? <Loader size="xs" /> : 'Change password'}
        </BizPayPrimaryButton>
      </Group>
    </form>
  );
};

export { ChangePasswordForm };
