import { FC, useEffect, useState } from 'react';

import { groupBy, max, useAuthentication } from 'common';
import {
  LoanApplicationCustomerStatus,
  ProductFrequencyType,
  useProductHelpers,
  useRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanLazyQuery,
  useRetrieveLoanFacilityForLoggedInUsersEntityLazyQuery,
  useRetrieveRepaymentPlansLazyQuery,
  useUpdateLoanApplicationCustomerStatusMutation,
  useUpdateLoanApplicationProductMutation,
} from 'graphql-library';
import {
  BizPayBackButton,
  BizPayContinueButton,
  BizPayLoader,
  BizPayModal,
  Grid,
  Group,
  PageHeading,
  Radio,
  ScrollArea,
  Stack,
  Sx,
  Text,
  useBizPayNotification,
  useForm,
} from 'ui';

import { LoanApplicationDetails } from '../LoanApplicationDetails';

import { loanApplicationSelectRepaymentPlanFormZodResolver } from './LoanApplicationSelectRepaymentPlanModal.helpers';
import {
  LoanApplicationSelectRepaymentPlanFormData,
  LoanApplicationSelectRepaymentPlanInvoice,
  LoanApplicationSelectRepaymentPlanModalProps,
  RepaymentPlan,
} from './LoanApplicationSelectRepaymentPlanModal.types';

import { useSignOut } from '../../hooks';

const LoanApplicationSelectRepaymentPlanModal: FC<LoanApplicationSelectRepaymentPlanModalProps> = ({
  isOpen,
  loanApplication: { id: loanApplicationId, niceId: loanApplicationNiceId },
  onClose,
  onContinue,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { calculateLoanAmountInCents, generateProductLoanRepaymentDescription } = useProductHelpers();
  const { signOut } = useSignOut();

  const [invoiceDocumentSignedUrl, setInvoiceDocumentSignedUrl] = useState<string>();
  const [remainingLoanFacilityLimit, setRemainingLoanFacilityLimit] = useState<number>();
  const [repaymentPlans, setRepaymentPlans] = useState<RepaymentPlan[][]>([]);
  const [selectedProductId, setSelectedProductId] = useState<RepaymentPlan['id']>();
  const [totalInvoiceAmountInCents, setTotalInvoiceAmountInCents] =
    useState<LoanApplicationSelectRepaymentPlanInvoice['totalAmountInCents']>();

  const [
    executeRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanQuery,
    { loading: isRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanLoading },
  ] = useRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanApplication: { invoice, product } }) => {
      setInvoiceDocumentSignedUrl(invoice?.document.signedUrl ?? undefined);
      setSelectedProductId(product?.id);
      setTotalInvoiceAmountInCents(invoice?.totalAmountInCents);

      executeRetrieveRepaymentPlansQuery();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve your loan application',
      });
    },
  });

  const [executeRetrieveLoanFacilityForLoggedInUsersEntityQuery, { loading: isRetrieveLoanFacilityForLoggedInUsersEntityLoading }] =
    useRetrieveLoanFacilityForLoggedInUsersEntityLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrieveLoanFacilityForLoggedInUsersEntity: { remainingLimitInCents } }) => {
        setRemainingLoanFacilityLimit(remainingLimitInCents);

        executeRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanQuery({
          variables: {
            id: loanApplicationId,
          },
        });
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve your loan facility',
        });
      },
    });

  const [executeRetrieveRepaymentPlansQuery, { loading: isRetrieveProductsLoading }] = useRetrieveRepaymentPlansLazyQuery({
    onCompleted: ({ retrieveProducts: returnedRepaymentPlans }) => {
      const repaymentPlansGroupedByFrequencyType = groupBy(returnedRepaymentPlans, 'frequencyType');

      const fortnightlyRepaymentPlans = repaymentPlansGroupedByFrequencyType[ProductFrequencyType.Fortnight];
      const monthlyRepaymentPlans = repaymentPlansGroupedByFrequencyType[ProductFrequencyType.Month];

      const totalFortnightlyRepaymentPlans = fortnightlyRepaymentPlans.length;
      const totalMonthlyRepaymentPlans = monthlyRepaymentPlans.length;

      const orderedRepaymentPlans = Array.from({
        length: max([totalFortnightlyRepaymentPlans, totalMonthlyRepaymentPlans]) ?? 0,
      }).map((_, index) => {
        const column1RepaymentPlan = monthlyRepaymentPlans[index];
        const column2RepaymentPlan = fortnightlyRepaymentPlans[index];

        return [column1RepaymentPlan, column2RepaymentPlan];
      });

      setRepaymentPlans(orderedRepaymentPlans);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve our repayment plans',
      });
    },
  });

  const [executeUpdateLoanApplicationCustomerStatusMutation, { loading: isUpdateLoanApplicationCustomerStatusLoading }] =
    useUpdateLoanApplicationCustomerStatusMutation({
      onCompleted: ({ updateLoanApplicationCustomerStatus: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message: `Unable to update your loan application - ${message}`,
        });
      },
    });

  const [executeUpdateLoanApplicationProductMutation, { loading: isUpdateLoanApplicationProductLoading }] =
    useUpdateLoanApplicationProductMutation({
      onCompleted: ({ updateLoanApplicationProduct: { customerStatus } }) => {
        onContinue(customerStatus);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to update your loan application',
        });
      },
    });

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();
  const isLoading =
    isRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanLoading ||
    isRetrieveLoanFacilityForLoggedInUsersEntityLoading ||
    isRetrieveProductsLoading ||
    isUpdateLoanApplicationProductLoading;

  const {
    formState: { isValid },
    handleSubmit,
    register,
    setFocus,
  } = useForm<LoanApplicationSelectRepaymentPlanFormData>({
    mode: 'onChange',
    resolver: loanApplicationSelectRepaymentPlanFormZodResolver(),
    values: {
      productId: selectedProductId,
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    setFocus('productId');

    executeRetrieveLoanFacilityForLoggedInUsersEntityQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      closeOnEscape={!isLoading}
      opened={isOpen}
      size={875}
      subTitle={
        <LoanApplicationDetails invoiceDocumentSignedUrl={invoiceDocumentSignedUrl} loanApplicationNiceId={loanApplicationNiceId} />
      }
      title={
        <PageHeading
          flexContainerProps={{
            mb: 0,
          }}
          heading="Select repayment plan"
          size="h4"
        />
      }
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      <Stack h={500} mt="md">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isRetrieveLoanApplicationForLoanApplicationSelectRepaymentPlanLoading && (
              <BizPayLoader message="Retrieving your loan application, please wait..." />
            )}

            {isRetrieveLoanFacilityForLoggedInUsersEntityLoading && (
              <BizPayLoader message="Retrieving your loan facility, please wait..." />
            )}

            {isRetrieveProductsLoading && <BizPayLoader message="Retrieving our repayment plans, please wait..." />}

            {isUpdateLoanApplicationCustomerStatusLoading && (
              <BizPayLoader message="Returning to the upload invoice step, please wait..." />
            )}

            {isUpdateLoanApplicationProductLoading && <BizPayLoader message="Updating your loan application, please wait..." />}
          </Stack>
        ) : (
          <form
            id="loan-application-select-repayment-plan-form"
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
            onSubmit={handleSubmit(({ productId }) => {
              if (!getIsAuthenticated()) {
                signOut();
                return;
              }

              executeUpdateLoanApplicationProductMutation({
                variables: {
                  id: loanApplicationId,
                  productId: String(productId),
                },
              });
            })}
          >
            <Stack h="100%" w="100%">
              {remainingLoanFacilityLimit && totalInvoiceAmountInCents && (
                <ScrollArea h={345} mt="xs" w="100%">
                  {repaymentPlans.map(([column1RepaymentPlan, column2RepaymentPlan], index) => {
                    const column1RepaymentPlanLoanAmountInCents = column1RepaymentPlan
                      ? calculateLoanAmountInCents({
                          product: column1RepaymentPlan,
                          totalInvoiceAmountInCents,
                        })
                      : undefined;

                    const column2RepaymentPlanLoanAmountInCents = column2RepaymentPlan
                      ? calculateLoanAmountInCents({
                          product: column2RepaymentPlan,
                          totalInvoiceAmountInCents,
                        })
                      : undefined;

                    const isColumn1RepaymentPlanDisabled = column1RepaymentPlanLoanAmountInCents
                      ? column1RepaymentPlanLoanAmountInCents > remainingLoanFacilityLimit
                      : true;
                    const isColumn2RepaymentPlanDisabled = column2RepaymentPlanLoanAmountInCents
                      ? column2RepaymentPlanLoanAmountInCents > remainingLoanFacilityLimit
                      : true;

                    const disabledRadioButtonStyle: Sx = ({ colors }) => ({
                      opacity: 0.5,

                      ':hover': {
                        backgroundColor: colors['bizpay-teal'][0],
                      },
                    });

                    return (
                      <Grid
                        key={`repayment-plan-${column1RepaymentPlan ? column1RepaymentPlan.id : `col1-${index}`}-${
                          column2RepaymentPlan ? column2RepaymentPlan.id : `col2-${index}`
                        }`}
                        gutter={0}
                        mt={index === 0 ? undefined : '0.5rem'}
                        w="100%"
                        grow
                      >
                        <Grid.Col mr="0.25rem" span="auto">
                          {column1RepaymentPlan && (
                            <Radio
                              {...register('productId')}
                              disabled={isColumn1RepaymentPlanDisabled}
                              label={
                                <Stack>
                                  <Text weight="bold">{column1RepaymentPlan.displayName ?? column1RepaymentPlan.name}</Text>

                                  <Text mt="0.5rem">
                                    {generateProductLoanRepaymentDescription({
                                      product: column1RepaymentPlan,
                                      totalInvoiceAmountInCents,
                                    })}
                                  </Text>
                                </Stack>
                              }
                              name="productId"
                              p="xs"
                              sx={isColumn1RepaymentPlanDisabled ? disabledRadioButtonStyle : undefined}
                              value={column1RepaymentPlan.id}
                            />
                          )}
                        </Grid.Col>

                        <Grid.Col ml="0.25rem" pr={0} span="auto">
                          {column2RepaymentPlan && (
                            <Radio
                              {...register('productId')}
                              disabled={isColumn2RepaymentPlanDisabled}
                              label={
                                <Stack>
                                  <Text weight="bold">{column2RepaymentPlan.displayName ?? column2RepaymentPlan.name}</Text>

                                  <Text mt="0.5rem">
                                    {generateProductLoanRepaymentDescription({
                                      product: column2RepaymentPlan,
                                      totalInvoiceAmountInCents,
                                    })}
                                  </Text>
                                </Stack>
                              }
                              name="productId"
                              p="xs"
                              sx={isColumn2RepaymentPlanDisabled ? disabledRadioButtonStyle : undefined}
                              value={column2RepaymentPlan.id}
                            />
                          )}
                        </Grid.Col>
                      </Grid>
                    );
                  })}
                </ScrollArea>
              )}
            </Stack>

            <Text
              sx={({ colors }) => ({
                color: colors['bizpay-light-gray'][5],
                fontSize: '0.75rem',
              })}
            >
              Only eligible repayment plans based on your invoice amount will be selectable.
            </Text>

            <Group align="center" mt="xl" w="100%">
              <Group align="center" position="left" w="50%">
                <BizPayBackButton
                  onClick={() => {
                    if (!getIsAuthenticated()) {
                      signOut();
                      return;
                    }

                    executeUpdateLoanApplicationCustomerStatusMutation({
                      variables: {
                        customerStatus: LoanApplicationCustomerStatus.ConfirmInvoice,
                        id: loanApplicationId,
                      },
                    });
                  }}
                />
              </Group>

              <Group align="center" position="right" w="50%">
                <BizPayContinueButton disabled={!isValid} type="submit" />
              </Group>
            </Group>
          </form>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { LoanApplicationSelectRepaymentPlanModal };
