import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication, useNumberFormatter } from 'common';
import { useRetrievePaginatedInvoicesForLoggedInUsersEntityLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { Invoice, InvoicesTableProps } from './InvoicesTable.types';

import { useSignOut } from '../../hooks';

const InvoicesTable: FC<InvoicesTableProps> = ({ paginationOptions: { isPaginationEnabled = false, maxPageSize } }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { formatCurrency } = useNumberFormatter();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedInvoicesForLoggedInUsersEntityQuery, { loading: isLoading }] =
    useRetrievePaginatedInvoicesForLoggedInUsersEntityLazyQuery({
      onCompleted: ({ retrievePaginatedInvoicesForLoggedInUsersEntity: { count, data } }) => {
        setHasRetrievedData(true);
        setInvoices(data);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);

        if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
          push(`${route}?pageNumber=${currentPageNumber}`, undefined, {
            shallow: true,
          });
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve your invoices',
        });
      },
    });

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<Invoice>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('number', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Invoice #',
        minSize: 0,
        size: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('dateIssuedAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date issued',
        size: 120,
        sortDescFirst: false,
      }),
      columnHelper.accessor('dateDueAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date due',
        size: 120,
        sortDescFirst: false,
      }),
      columnHelper.accessor('totalAmountInCents', {
        cell: ({ getValue }) => {
          const totalAmountInCents = getValue();

          return totalAmountInCents ? formatCurrency(totalAmountInCents) : '-';
        },
        enableSorting: isSortingEnabled,
        header: 'Total amount',
        meta: {
          align: 'right',
        },
        sortDescFirst: false,
      }),
    ];
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<Invoice>({
    columns: getTableColumns(totalRecords),
    data: invoices,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedInvoicesForLoggedInUsersEntityQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  return (
    <TablePageLayout
      loadingMessage={isLoading ? 'Retrieving your invoices...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<Invoice> hasRetrievedData={hasRetrievedData} headerGroups={getHeaderGroups()} rowModel={getRowModel()} />
      }
    />
  );
};

export { InvoicesTable };
