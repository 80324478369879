import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication } from 'common';
import { useRetrieveEntityForLoggedInUserLazyQuery, useUpdateEntityDetailsMutation } from 'graphql-library';
import { BizPayLoadingOverlay, BusinessInfoForm, FullHeightContainer, useBizPayNotification } from 'ui';

import { Entity } from './BusinessInfoTab.types';

import { useSignOut } from '../../hooks';

const BusinessInfoTab: FC = () => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [entity, setEntity] = useState<Entity>();

  const [executeRetrieveEntityForLoggedInUserQuery, { loading: isRetrieveEntityForLoggedInUserLoading }] =
    useRetrieveEntityForLoggedInUserLazyQuery({
      onCompleted: ({ retrieveEntityForLoggedInUser: returnedEntity }) => {
        setEntity(returnedEntity);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve entity',
        });
      },
    });

  const [executeUpdateEntityDetailsMutation, { loading: isUpdateEntityDetailsLoading }] = useUpdateEntityDetailsMutation({
    onCompleted: ({ updateEntityDetails: returnedEntity }) => {
      setEntity(returnedEntity);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the entity details',
      });
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveEntityForLoggedInUserQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <FullHeightContainer>
      {isRetrieveEntityForLoggedInUserLoading && <BizPayLoadingOverlay message="Retrieving your entity..." />}

      {isUpdateEntityDetailsLoading && <BizPayLoadingOverlay message="Updating entity details..." />}

      {entity && (
        <BusinessInfoForm
          initialDefaultValues={{
            abn: entity.abn ?? '-',
            acn: entity.acn ?? '-',
            businessType: entity.type?.name,
            id: entity.id,
            legalName: entity.name,
            onboardedDate: dayjs(entity.createdAtUtc).toDate(),
            websiteUrl: entity.websiteUrl ?? '',
          }}
          onSubmit={({ websiteUrl }) => {
            if (!isAuthenticated) {
              signOut();
              return;
            }

            executeUpdateEntityDetailsMutation({
              variables: {
                id: entity.id,
                websiteUrl,
              },
            });
          }}
        />
      )}
    </FullHeightContainer>
  );
};

export { BusinessInfoTab };
