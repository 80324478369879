import { FC } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayCustomerPortalDynamicNextJsTitle, FullHeightContainer, FullPageLayout, PageHeading } from 'ui';

import { LoansTable } from '../LoansTable';

import { useNavigationRoutes } from '../../hooks';

const LoansPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayCustomerPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <FullPageLayout
        mainComponent={
          <FullHeightContainer>
            <LoansTable
              paginationOptions={{
                isPaginationEnabled: true,
              }}
            />
          </FullHeightContainer>
        }
        pageHeadingComponent={<PageHeading heading={title} size="h4" />}
      />
    </>
  );
};

export { LoansPage };
