import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanApplicationInvoiceDocumentQuery: DocumentNode = gql`
  query retrieveLoanApplicationInvoiceDocument($id: ID!) {
    retrieveDocument(id: $id) {
      id
      signedUrl
    }
  }
`;

const retrieveLoanApplicationForLoanApplicationUploadInvoiceModalQuery: DocumentNode = gql`
  query retrieveLoanApplicationForLoanApplicationUploadInvoiceModal($id: ID!) {
    retrieveLoanApplication(id: $id) {
      id
      invoiceDocument {
        id
        signedUrl
      }
    }
  }
`;

const retrieveRemainingLoanFacilityLimitQuery: DocumentNode = gql`
  query retrieveRemainingLoanFacilityLimit {
    retrieveLoanFacilityForLoggedInUsersEntity {
      id
      remainingLimitInCents
    }
  }
`;

const updateLoanApplicationInvoiceMutation: DocumentNode = gql`
  mutation updateLoanApplicationInvoice($id: ID!, $invoiceId: String!) {
    updateLoanApplicationInvoice(id: $id, invoiceId: $invoiceId) {
      customerStatus
      id
    }
  }
`;

export {
  retrieveLoanApplicationInvoiceDocumentQuery,
  retrieveLoanApplicationForLoanApplicationUploadInvoiceModalQuery,
  retrieveRemainingLoanFacilityLimitQuery,
  updateLoanApplicationInvoiceMutation,
};
