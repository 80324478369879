import { FC } from 'react';

import { BaseLink } from '../BaseLink';

import { ExternalLinkProps } from './ExternalLink.types';

const ExternalLink: FC<ExternalLinkProps> = ({ children, ...otherProps }) => {
  return (
    <BaseLink rel="noreferrer" target="_blank" {...otherProps}>
      {children}
    </BaseLink>
  );
};

export { ExternalLink };
