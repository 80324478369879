import { useRouter } from 'next/router';
import { BizPayNavigationLinkProps } from 'ui';

import { USE_NAVIGATION_ROUTE_CONSTANTS } from './useNavigationRoutes.constants';
import {
  ProtectedSideMenuNavigationRoutes,
  ProtectedUserDropdownMenuNavigationRoutes,
  PublicRoute,
  UseNavigationRoutes,
} from './useNavigationRoutes.types';

const { sideMenuRoutes, userDropdownMenuRoutes } = USE_NAVIGATION_ROUTE_CONSTANTS;

const useNavigationRoutes = (): UseNavigationRoutes => {
  const { push } = useRouter();

  const buildNavigationBarLinks: UseNavigationRoutes['buildNavigationBarLinks'] = (currentRoute: string) => {
    if (currentRoute === PublicRoute.SignIn) {
      return [];
    }

    const baseCurrentRoute = extractBaseRoute(currentRoute);

    return Object.keys(sideMenuRoutes).map((sideMenuRoute): BizPayNavigationLinkProps => {
      const baseSideMenuRoute = extractBaseRoute(sideMenuRoute);

      const { menuIcon, title } = sideMenuRoutes[baseSideMenuRoute as ProtectedSideMenuNavigationRoutes];

      return {
        icon: menuIcon,
        isActive: baseCurrentRoute === baseSideMenuRoute,
        label: title,
        linkOptions: {
          onClick: () => push(sideMenuRoute),
        },
      };
    });
  };

  const buildUserDropdownMenuNavigationLinks: UseNavigationRoutes['buildUserDropdownMenuNavigationLinks'] = () => {
    return Object.keys(userDropdownMenuRoutes).map((UserDropdownMenuRoute) => {
      const { menuIcon, title } = userDropdownMenuRoutes[UserDropdownMenuRoute as ProtectedUserDropdownMenuNavigationRoutes];

      return {
        icon: menuIcon,
        label: title,
        route: UserDropdownMenuRoute,
        onClick: () => push(UserDropdownMenuRoute),
      };
    });
  };

  const extractBaseRoute = (route: string) => {
    const routeParts = route.split('/');
    return routeParts.slice(0, 2).join('/');
  };

  const findPageByRoute: UseNavigationRoutes['findPageByRoute'] = (route: string) => {
    const baseRoute = extractBaseRoute(route);

    return (
      sideMenuRoutes[baseRoute as ProtectedSideMenuNavigationRoutes] ||
      userDropdownMenuRoutes[baseRoute as ProtectedUserDropdownMenuNavigationRoutes]
    );
  };

  return {
    buildNavigationBarLinks,
    buildUserDropdownMenuNavigationLinks,
    findPageByRoute,
  };
};

export { useNavigationRoutes };
