import { FC } from 'react';

import Link from 'next/link';
import { Text } from 'ui';

import { BaseLinkProps } from './BaseLink.types';

const BaseLink: FC<BaseLinkProps> = ({ children, linkProps: { href, ...otherLinkProps }, nonLinkText, rel, target, textProps }) => {
  const { color = 'dimmed', ...otherTextProps } = textProps ?? {};

  return (
    <Text
      color={color}
      sx={({ colors }) => ({
        a: {
          color: colors['bizpay-teal'][5],
        },
      })}
      {...otherTextProps}
    >
      {nonLinkText}{' '}
      <Link href={href} rel={rel} target={target} {...otherLinkProps}>
        {children}
      </Link>
    </Text>
  );
};

export { BaseLink };
