import { FC } from 'react';

import { BizPayModal, ForgotPasswordForm, Stack } from 'ui';

import { ResetPasswordProps } from './ResetPasswordModal.types';

const ResetPasswordModal: FC<ResetPasswordProps> = ({ email, isLoading, isOpen, onSubmit, onClose }) => {
  return (
    <BizPayModal opened={isOpen} title="Reset password" withCloseButton onClose={onClose}>
      <Stack mt="md">
        <ForgotPasswordForm email={email} isLoading={isLoading} onSubmit={({ email }) => onSubmit(email)} />
      </Stack>
    </BizPayModal>
  );
};

export { ResetPasswordModal };
