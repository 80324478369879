import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import isURL from 'validator/lib/isURL';
import { z } from 'zod';

const businessInfoFormZodResolver = () => {
  return zodResolver(
    z.object({
      websiteUrl: z.string().refine(
        (value) => {
          if (!value) {
            return false;
          }

          return isURL(value, {
            protocols: ['http', 'https'],
            require_protocol: true,
          });
        },
        {
          message: 'Please enter a valid website url',
        },
      ),
    }),
  );
};

export { businessInfoFormZodResolver };
